import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import { Select, MenuItem, InputLabel } from "@mui/material";
import EligibilityTenureForm from "./EligibilityTenureForm";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import "./personalLoan.css";
import {
  propertyLoanDecisionEngine1API,
  propertyLoanPreDe1CallAPI,
  propertyLoanFetchDataAPI,
} from "../../actioncreators/actioncreators";
import LoadingSpinner from "../../fields/spinner.jsx";
import { toast } from "react-toastify";

const EligibilityCheckForm = ({onContinue,onBack,ckycSuccess,changeStatus}) => {
  const [showEligibilityTenureForm, setShowEligibilityTenureForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [currentFormData, setCurrentFormData] = React.useState({});
  const [purposeDropdown, setPurposeDropdown] = React.useState([]);
  const [tenureDropdown, setTenureDropdown] = React.useState([]);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); 
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    income: "",
    annualTurnover: "",
    existingObligations: "",
    loanAmount: "",
    tenure: "",
    purpose: "",
    monthlyIncome: "",
    exactPurpose:""
  });
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; 

    setErrorDisplayed(true);
    errorDisplayedRef.current = true; 

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyLoanPreDe1CallAPI();
        setFormData({
          income: response?.income,
          existingObligations: response?.existingObligations,
          loanAmount: response?.loanAmount,
          tenure: response?.tenure,
          purpose: response?.purpose,
          exactPurpose:response.exactPurpose,
        });
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchPurposeValues = async () => {
    setPurposeDropdown([]);
    try {
      let payload = {
        source: "json_value",
        key: "PURPOSE",
        type: "LAP_LOAN",
      };
      const response = await propertyLoanFetchDataAPI(payload);
      setPurposeDropdown(response?.data?.[0]?.List);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const fetchTenureValues = async () => {
    setTenureDropdown([]);
    try {
      let payload = {
        source: "json_value",
        key: "TENURE_MONTHS",
        type: "LAP_LOAN",
      };
      const response = await propertyLoanFetchDataAPI(payload);
      setTenureDropdown(response?.data?.[0]?.values);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  useEffect(() => {
    fetchPurposeValues();
    fetchTenureValues();
  }, []);

  useEffect(() => {
    validateForm();
  }, [formData]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;

     if (
      name === "existingObligations" ||
      name === "loanAmount" ||
      name === "income"
    ) {
      const sanitizedValue = value.replace(/\D/g, "");
      const regex = /^[0-9]{0,8}$/;
      if (regex.test(sanitizedValue)) {
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
      } else {
        console.error(
          "Invalid input. Please enter a number with up to 8 digits."
        );
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    validateForm();
  };

 

  const handleBack = () => {
    changeStatus('PROPERTY_DETAILS')
  };

  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};

  const navigate = useNavigate();

  const handleContinue = async () => {
    // setShowEligibilityTenureForm(true);
    setIsButtonClicked(true);
    setIsLoading(true);

    const data = {
      income: formData.income,
      emi: formData.existingObligations,
      amount: formData.loanAmount,
      purpose: formData.purpose,
      tenure: formData.tenure?.toString(),
      // exactPurpose: formData.exactPurpose,
    };

    try {
      const response = await propertyLoanDecisionEngine1API(data);

      const {
        interestRate,
        maxLoanAmount,
        maxTenure,
        minimumLoan,
        tentativeEmi,
        minimumTenure,
        loanAmount,
        loanTenure,
        tenure,
      } = response.data;

      if (response.status === 200 && response.data.businessStatusCode === 2) {
        toast.success("Financial Details Added Successfully");
        // setShowEligibilityTenureForm(true);
        changeStatus('ELIGIBILITY_CONFIRMED')
        navigate(EligibilityTenureForm, {
          state: {
            interestRate,
            maxLoanAmount,
            maxTenure,
            minimumLoan,
            tentativeEmi,
            minimumTenure,
            ap_id,
            loan_app_id,
            loanAmount,
            loanTenure,
            tenure,
          },
        });
      } else if (
        response.status === 200 &&
        response.data.businessStatusCode === 1
      ) {
        setShowEligibilityTenureForm(false);
        toast.info(response.data.message);
      } else {
        console.error("Invalid loan data in response.");
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const validateForm = () => {
    const {
      income,
      existingObligations,
      loanAmount,
      tenure,
      purpose,
    } = formData;

    // Validate each field
    const isMonthlyIncomeValid = /^\d+$/.test(income);
    const isExistingObligationEMIValid = /^\d+$/.test(existingObligations);
    const isLoanAmountValid = /^\d+$/.test(loanAmount);
    const isTenureValid = tenure && tenure.trim() !== "";
    const isPurposeValid = purpose && purpose.trim() !== "";
    // Update the overall form validity
    setIsFormValid(
        isMonthlyIncomeValid &&
        isExistingObligationEMIValid &&
        isLoanAmountValid &&
        isTenureValid &&
        isPurposeValid
    );
  };

  return (
    <>
      {!showEligibilityTenureForm ? (
        <>
          <h6 className="text">Basic Financial Details</h6>
          <p className="offer py-3">
            Sum of incomes and obligations of Primary and all Co-applicants
            should be provided here.
          </p>
          <form className="mt-4">
            <div className="row">
              <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="income"
                  label="₹ Monthly Income"
                  fullWidth
                  variant="outlined"
                  value={formData.income}
                  onChange={handleChange}
                />
                <p className="msg mt-1">
                  Total income from Salary, Business, Pension etc.
                </p>
              </div>
              <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="existingObligations"
                  label="₹ Existing Monthly Obligation EMI"
                  fullWidth
                  variant="outlined"
                  value={formData.existingObligations}
                  onChange={handleChange}
                />
                <p className="msg mt-1">Total monthly obligations and expenses</p>
              </div>
            </div>

            <h6 className="text mt-2">Loan Requirement</h6>
            <div className="row mt-4">
              <div className="col-md-6 ">
                <ArthaTextField
                  name="loanAmount"
                  label="₹ Loan Amount"
                  defaultValue=""
                  fullWidth
                  variant="outlined"
                  value={formData.loanAmount}
                  onChange={handleChange}
                />
                <p className="msg mt-1">Preferred Loan Amount</p>
              </div>
              <div className="col-md-6">
                <ArthaFormControl fullWidth>
                  <ArthaInputLabel id="tenure-label">Tenure</ArthaInputLabel>
                  <Select
                    labelId="tenure-label"
                    id="tenure"
                    name="tenure"
                    required
                    value={formData.tenure}
                    onChange={handleChange}
                  >
                    {tenureDropdown &&
                      tenureDropdown.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <p className="msg mt-1">Preferred Tenure</p>
                </ArthaFormControl>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="qualification-label">Purpose</InputLabel>
                  <Select
                    labelId="qualification-label"
                    label="Purpose"
                    name="purpose"
                    value={formData.purpose}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        purpose: e.target.value,
                        exactPurpose:
                          e.target.value === "Other"
                            ? formData.exactPurpose
                            : "",
                      });
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: "custom-scrollbar", // Apply custom scrollbar class
                        style: {
                          maxHeight: 120, // Adjust the max height to control the size of the dropdown
                        },
                      },
                    }}
                  >
                    {purposeDropdown &&
                      purposeDropdown.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <p className="msg mt-1">Purpose of the loan</p>
                </ArthaFormControl>
              </div>
              {formData.purpose === "Other" && (
                <div className="col-md-6 mt-2">
                  <ArthaFormControl fullWidth>
                    <ArthaTextField
                      label="Exact Purpose"
                      name="exactPurpose"
                      value={formData.exactPurpose}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          exactPurpose: e.target.value,
                        });
                      }}
                    />
                  </ArthaFormControl>
                </div>
              )}
            </div>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton variant="contained" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  // Add style based on form validity
                  style={{
                    backgroundColor: isFormValid
                      ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                      : "rgb(255 98 0 / 39%)",
                    opacity: isButtonClicked ? 0.39 : 1,
                    pointerEvents: isButtonClicked ? "none" : "auto", // Lower opacity when form is not valid
                  }}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <EligibilityTenureForm
          onContinue={onContinue}
          data={currentFormData}
          onCancel={() => setShowEligibilityTenureForm(false)}
          ckycSuccess={ckycSuccess}
        />
      )}
    </>
  );
};

export default EligibilityCheckForm;
