import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField.js";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ApplicantSummaryDetails from "./ApplicantsSummary.jsx";

import {
  propertyLoanFetchDataAPI,
  propertyLoanFetchRetriedDetailsAPI,
  propertyLoanStoreRetriedDetailsAPI,
  propertyLoanBackPageAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import EligibilityCheckForm from "../PersonalLoan/EligibilityCheckForm.jsx";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

import { Button, InputLabel, Select, MenuItem } from "@mui/material";
import UploadDeceasedDoc from "./UploadDeceasedDoc.jsx";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import AddBank from "../BusinessLoan/AddBank.jsx";
import UploadApplicantWithoutPanDoc from "./UploadApplicantWithoutPanDoc.jsx";
import UploadRetriedDoc from "./UploadRetriedDoc.jsx";

const QualificationIncomeRetriedForm = ({ changeApplicantStatus }) => {
  const [selectedDocs, setSelectedDocs] = useState({ document: [] });
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showReference, setShowReference] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [openBankDialog, setOpenBankDialog] = React.useState(false);
  const [bankDetails, setBankDetails] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  const [qualificationDropdown, setQualificationDropdown] = React.useState([]);
  const [occupationDropdown, setOccupationDropdown] = React.useState([]);
  const [incomeSourceDropdown, setIncomeSourceDropdown] = React.useState([]);
  const fileInputRefID = useRef(null);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const navigate = useNavigate();
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const [isLoading3, setIsLoading3] = useState(false);

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const [formData, setFormData] = useState({
    qualification: "",
    monthlyObligations: "",
    monthlyIncome: "",
  });

  React.useEffect(() => {
    setFormData({
      qualification: response?.qualification || "",
      monthlyIncome: response?.monthlyIncome || "",
      monthlyObligation: response?.monthlyObligation || "",
    });
  }, [response]);
  const [currentEmployeer, setCurrentEmployeer] = React.useState({
    selectedDocs: "",
  });

  useEffect(() => {
    validateForm();
  }, [formData, selectedDocs]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "qualification") {
      setFormData({
        ...formData,
        qualification: value,
      });
    } else if (name === "monthlyObligations" || name === "monthlyIncome") {
      const sanitizedValue = value.replace(/\D/g, "");
      const regex = /^[0-9]{0,8}$/;

      if (regex.test(sanitizedValue)) {
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
      } else {
        console.error(
          "Invalid input. Please enter a number with up to 8 digits."
        );
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    validateForm(); // Validate on every change
  };

  const validateForm = () => {
    const { monthlyIncome, monthlyObligations, qualification,occupation,incomeSource } = formData;

    // Validate each field

    const isMonthlyIncomeValid = /^\d+$/.test(monthlyIncome);
    const isMonthlyObligationValid = /^\d+$/.test(monthlyObligations);
    const hasBankDetails = !!bankDetails;
    const hasSupportingDocuments = selectedDocs.document.length > 0;
    const isQualificationValid = !!qualification;
    const isOccupationValid = !!occupation;
    const isIncomeSourceValid = !!incomeSource;
    // Update the overall form validity
    setIsFormValid(
      isMonthlyIncomeValid &&
        isMonthlyObligationValid &&
        hasBankDetails &&
        hasSupportingDocuments &&
        isQualificationValid && isOccupationValid && isIncomeSourceValid
    );
  };

  useEffect(() => {
    //fetching Qualification dropdown
    let apiPayload = {
      type: "BORROWER_QUALIFICATION",
      key: "BORROWER_QUALIFICATION",
      source: "json_value",
    };
    fetchDropDownValues(apiPayload, "BORROWER_QUALIFICATION");
    let apiPayload1 = {
      source: "json_value",
      type: "OCCUPATION",
      key: "Retired/Homemaker/Student",
    };
    fetchDropDownValues(apiPayload1, "OCCUPATION");
  }, []);
  const fetchDropDownValues = async (apiPayload, dropdownType) => {
    setQualificationDropdown([]);
    setOccupationDropdown([]);
    try {
      const response = await propertyLoanFetchDataAPI(apiPayload);

      if (dropdownType == "BORROWER_QUALIFICATION") {
        setQualificationDropdown(response?.data?.[0]?.values);
      } else if (dropdownType == "OCCUPATION") {
        setOccupationDropdown(response?.data?.[0]?.List || []);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading3(true);

    let payload = {
      qualification: formData.qualification,
      monthlyIncome: parseInt(formData.monthlyIncome, 10),
      monthlyObligations: parseInt(formData.monthlyObligations, 10),
      banksDetail: bankDetails,
      supportingDocuments: selectedDocs.document,
      occupation: formData.occupation,
      incomesource: formData.incomeSource,
    };
    console.log(payload);
    try {
      const response = await propertyLoanStoreRetriedDetailsAPI(payload);

      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        changeApplicantStatus(response.data.nextPage);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error storing employee history:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading3(false);
      setIsButtonClicked(false);
    }
  };
  const handleUploadDocument = () => {
    setPopupOpen(true);
  };

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      try {
        const response = await propertyLoanFetchRetriedDetailsAPI();
        console.log(response);
        setResponse(response.data);

        const data = response.data;

        // Set individual form fields using setFormData or other setters
        setFormData((prevFormData) => ({
          ...prevFormData,
          qualification: data.qualification || "",
          monthlyIncome: data.monthlyIncome || "",
          monthlyObligations: data.monthlyObligations || "",
        }));

        const firmDetailsData = response.data;
        if (firmDetailsData && firmDetailsData.length > 0) {
          const firmDetail = firmDetailsData[0];
          const bankAccDetails = firmDetail.bankAccDetails;
          setBankDetails(
            bankAccDetails && Object.keys(bankAccDetails).length > 0
          );
          // setAddressAdded(address && Object.keys(address).length > 0);
        } else {
          // setAddressAdded(false);
          setBankDetails(false);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchProfessionalDetails();
  }, [navigate]);

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor: isFormValid
      ? "rgba(255, 115, 0, 1)"
      : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };
  const removeExistingDoc = (documentType) => {
    setSelectedDocs((prevState) => {
      const updatedDocuments = prevState.document.filter(
        (doc) => doc.documentType !== documentType
      );
      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handlePopupClose = (selectedFileNames) => {
    console.log(selectedFileNames);

    if (selectedFileNames.length > 0) {
      setSelectedDocs((prevSelectedEmployer) => {
        // Filter out documents that have the same documentType as the new ones
        const filteredDocuments = prevSelectedEmployer.document
          ? prevSelectedEmployer.document.filter(
              (prevDoc) =>
                !selectedFileNames.some(
                  (newDoc) => newDoc.documentType === prevDoc.documentType
                )
            )
          : [];

        // Merge the filtered documents with the new documents
        return {
          ...prevSelectedEmployer,
          document: [...filteredDocuments, ...selectedFileNames],
        };
      });
    }

    setPopupOpen(false);
  };

  const handleFileChangeID = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,
          registrationCertificateDoc: {
            ...formData.registrationCertificateDoc,
            documentData: e.target.result.split(",")[1], // Assuming base64 data
            documentName: file.name,
            documentType: file.type,
          },
        });
      };
      reader.readAsDataURL(file);
    }

    validateForm(); // Validate after file upload
  };

  const cancelBankDialog = () => {
    setOpenBankDialog(false);
  };

  const onBankDataSave = (data) => {
    setBankDetails(data);
    setOpenBankDialog(false);
  };

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <a
          href="#"
          className="text fw-bold mt-3"
          style={{ color: "orange", textDecoration: "none" }}
        >
          Go To Applicant Summary
        </a>
      </div>

      <div className="mt-1">
        <h6 className="text mt-4" style={{ fontWeight: 750 }}>
          Qualification & Income
        </h6>
        <p className="text fw-normal">
          Share Your Academic background,Monthly Income/Obligation and Bank
          account details (as applicable)
        </p>
      </div>

      <form className="mt-5">
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaFormControl fullWidth>
              <InputLabel id="qualification-label">Qualification</InputLabel>
              <Select
                labelId="qualification-label"
                id="qualification"
                name="qualification"
                value={formData.qualification}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    qualification: e.target.value,
                  })
                }
              >
                {qualificationDropdown &&
                  qualificationDropdown.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </ArthaFormControl>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaFormControl fullWidth>
              <InputLabel id="qualification-label">Occupation</InputLabel>
              <Select
                labelId="qualification-label"
                id="occupation"
                name="occupation"
                value={formData.occupation}
                onChange={async (e) => {
                  const selectedOccupation = e.target.value;
                  setFormData({
                    ...formData,
                    occupation: selectedOccupation,
                  });

                  try {
                    const response = await propertyLoanFetchDataAPI({
                      source: "json_value",
                      type: selectedOccupation,
                      key: "INCOME_SOURCE",
                    });

                    // Assuming response?.data?.[0]?.List contains the dropdown data
                    setIncomeSourceDropdown(response?.data?.[0]?.List);
                  } catch (error) {
                    console.error("Error fetching income source data:", error);
                  }
                }}
              >
                {occupationDropdown &&
                  occupationDropdown.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </ArthaFormControl>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaFormControl fullWidth>
              <InputLabel id="qualification-label">Income Source</InputLabel>
              <Select
                labelId="qualification-label"
                id="incomeSource"
                name="incomeSource"
                value={formData.incomeSource}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    incomeSource: e.target.value,
                  })
                }
                disabled={!formData.occupation}
              >
                {incomeSourceDropdown &&
                  incomeSourceDropdown.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </ArthaFormControl>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <ArthaTextField
              id="outlined-basic"
              name="monthlyIncome"
              label="Monthly Income (₹)"
              fullWidth
              variant="outlined"
              value={formData.monthlyIncome}
              onChange={handleChange}
              InputProps={{
                style: {
                  height: "50px", // Adjust the height as needed
                },
              }}
              inputProps={{
                style: {
                  padding: "18px 14px", // Adjust padding to center the text vertically
                },
              }}
            />
          </div>
          <div className="col-md-6">
            <ArthaTextField
              id="outlined-basic"
              name="monthlyObligations"
              label="Monthly Obligations (₹)"
              fullWidth
              variant="outlined"
              value={formData.monthlyObligations}
              onChange={handleChange}
              InputProps={{
                style: {
                  height: "50px", // Adjust the height as needed
                },
              }}
              inputProps={{
                style: {
                  padding: "18px 14px", // Adjust padding to center the text vertically
                },
              }}
            />
          </div>
        </div>

        <div className="row mt-3 mt-md-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="row">
              <div className="col-8 col-md-7 mt-3">
                <p className="msg">Bank Account Details</p>

                {bankDetails ? (
                  <>
                    <FaCheckCircle
                      className="badge-icon"
                      style={{ marginTop: "-7px", color: "green" }}
                    />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      {" "}
                      Bank Details Added!
                    </span>
                  </>
                ) : (
                  <>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      {" "}
                      Not Added
                    </span>
                  </>
                )}
              </div>
              <div className="col-4 col-md-5">
                <input
                  type="file"
                  ref={fileInputRefID}
                  style={{ display: "none" }}
                  onChange={handleFileChangeID}
                />
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none mt-4"
                  onClick={() => {
                    setOpenBankDialog(true);
                  }}
                >
                  {bankDetails ? "Edit" : "Add Bank"}
                </Button>
                <AddBank
                  openBankDialog={openBankDialog}
                  onCancel={cancelBankDialog}
                  onBankDataSave={onBankDataSave}
                  //   firmDetails={firmDetails} // Pass firm details here
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md- col-lg-6 mt-4">
            <div className="row mt-3">
              <div className="col-9">
                {/* <p className="msg font-weight-bold mb-2">Supporting Documents</p> */}
                <div>
                  <p class="msg mb-0">Supporting Documents</p>
                  <p class="msg mt-1 ms-2" style={{ color: "blue" }}>
                    You may attach any previous employment or income related
                    documents
                  </p>
                </div>

                {selectedDocs.document && selectedDocs.document.length > 0 ? (
                  selectedDocs.document.map((doc, index) => (
                    <React.Fragment key={index}>
                      <FaCheckCircle
                        className="badge-icon"
                        style={{ marginTop: "-7px", color: "green" }}
                      />
                      <span
                        className="mt-2 upload-lable"
                        style={{ position: "relative", top: "-10px" }}
                      >
                        {doc.documentType} <br />
                      </span>
                    </React.Fragment>
                  ))
                ) : (
                  <div>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      Not Uploaded
                    </span>
                  </div>
                )}
              </div>
              <div className="col-2">
                <input
                  type="file"
                  //   ref={fileInputRefID}
                  style={{ display: "none" }}
                  onChange={handleFileChangeID}
                />
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none mt-5"
                  onClick={handleUploadDocument}
                >
                  Upload
                </Button>
                <UploadRetriedDoc
                  open={isPopupOpen}
                  existingDocuments={selectedDocs.document}
                  onRemove={removeExistingDoc}
                  handleClose={handlePopupClose}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6 d-flex justify-content-end mt-4">
            <ArthaClearButton variant="contained" onClick={handleBack}>
              Back
            </ArthaClearButton>
          </div>

          <div className="col-6 d-flex justify-content-start mt-4">
            <ArthaContinueButton
              variant="contained"
              onClick={handleContinue}
              disabled={!isFormValid}
              style={{
                backgroundColor: isFormValid
                  ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                  : "rgb(255 98 0 / 39%)",
                opacity: isButtonClicked ? 0.39 : 1,
                pointerEvents: isButtonClicked ? "none" : "auto", // Lower opacity when form is not valid
              }}
            >
              {isLoading3 ? (
                <LoadingSpinner style={{ pointerEvents: "none" }} />
              ) : (
                "Continue"
              )}
            </ArthaContinueButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default QualificationIncomeRetriedForm;
