import React, { useState, useRef, useEffect } from "react";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import { Button } from "@mui/material";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import AccountAggregator from "./AccountAggregator.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import LoanOffer from "./LoanOffer.jsx";
import LoadingComponent from "./LoadingPage.jsx";
import UploadBankStatementPopup from "./UploadBankStatementPopup.jsx";
import axios from "axios";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import {
  propertyLoanBankStatementAnalysisAPI,
  propertyLoanGetBankStatementAPI,
  propertyLoanBackPageAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import IncomeAssessmentDetails from "./BusinessIncomeAssessment.jsx";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
const BankStatement = ({ changeApplicantStatus }) => {
  const fileInputRefs = useRef([]);
  const [showIncomeAssessment, setShowIncomeAssessment] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [isPopupOpen1, setPopupOpen1] = useState(false);
  const [isStatementUploaded, setIsStatementUploaded] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [fileData, setFileData] = useState({});
  const [selectedBankId, setSelectedBankId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingbtn, setIsLoadingbtn] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await propertyLoanGetBankStatementAPI();
        if (result) {
          let tempArray = result.bankNames;
          setBankData(tempArray);
          if (Array.isArray(tempArray) && tempArray.length > 0) {
            tempArray.map((item, index) => {
              let temp = { id: item.id, files: [] };
              setFileNames((prevFileNames) => [...prevFileNames, temp]);
            });
          }
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    console.log("fileNames", fileNames);
  }, [ap_id, loan_app_id]);

  const handleContinue = async () => {
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoadingbtn(true);
    try {
      const bankStatementsPayload = bankData.map((bank) => ({
        bankId: bank.bankId,
        bankName: bank.bankName,
        accountNumber: bank.accountNumber,
        bankStatements: (
          fileNames.find((file) => file.id === bank.bankId)?.files || []
        ).map((file) => ({
          bankStatement: file.bankStatement || "",
          password: file.password || "",
        })),
      }));

      const payload = {
        bankStatementAll: bankStatementsPayload,
      };

      const response = await propertyLoanBankStatementAnalysisAPI(payload);
      if (response.status === 200 && response.data.businessStatusCode === 2) {
        setPopupOpen1(true);
      } else {
        toast.error(
          response.data.message +
            " Please Upload the latest Bank Statement and proceed."
        );
        setIsLoading(false);
      }
    } catch (error) {
      handleApiError(error);
      toast.error(
        "Failed to Upload Bank statement, Please Upload the latest Bank Statement"
      );
      console.error("Error:", error);
      setIsLoading(false);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoadingbtn(false);
      setIsButtonClicked(false);
    }
  };

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const [bankName, setBankName] = useState();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [id, setId] = useState();
  const [selectedBank, setSelectedBank] = useState({});

  const handlePopupClose = (filesData, id) => {
    setId(id);
    for (let index = 0; index < fileNames.length; index++) {
      const element = fileNames[index];
      if (element.id == id) {
        for (let index = 0; index < element.files.length; index++) {
          const file = element.files[index];
          filesData.push(file);
        }
      }
    }
    const updatedFileNames = fileNames.filter((item) => item.id !== id);
    let temp = { id: id, files: filesData };
    console.log(fileNames);
    //setFileNames( ( prevFileNames ) => [ ...prevFileNames, temp ] );
    const newFileNames = [...updatedFileNames, temp];
    setFileNames(newFileNames);
    setPopupOpen(false);
  };

  const handleUploadButtonStatement = (bank) => {
    setPopupOpen(true);
    setSelectedBank(bank);
    setSelectedBankId(bank.bankId);
  };

  const removeFileFromFileNames = (file, bankId) => {
    console.log(file, bankId);
    console.log(fileNames);
    let bankObject;
    for (let index = 0; index < fileNames.length; index++) {
      const element = fileNames[index];
      if (element.id == bankId) {
        bankObject = element;
      }
    }
    if (bankObject) {
      let existingfiles = bankObject.files;
      let latestArray = [];
      for (let index = 0; index < existingfiles.length; index++) {
        const tempFile = existingfiles[index];
        if (tempFile.name != file.name) {
          latestArray.push(tempFile);
        }
      }
      const updatedFileNames = fileNames.filter((item) => item.id !== bankId);
      let temp = { id: bankId, files: latestArray };
      const newFileNames = [...updatedFileNames, temp];
      setFileNames(newFileNames);
    }
  };

  const handleDialogClose1 = () => {
    setPopupOpen1(false);
    changeApplicantStatus("");
  };
  return (
    <>
      <div className="row">
        <div className="col-4 ">
          <h6>Bank Statement</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
          <p className="small-heading kyc-form-text-size">
            Please download the statement for the required period as mentioned
            below from your bank website in PDF format and upload the same.
          </p>
        </div>
      </div>
      <div className="container">
        {bankData &&
          bankData.map((bank) => {
            const bankId = bank.bankId;
            const matchedFile = fileNames.find((file) => file.id === bankId);

            return (
              <div key={bank.bankId} className="row">
                <div className="col-12 col-md-8 mt-0 mt-md-4">
                  <span className="msg">{`${bank.bankName} Statement from ${bank.bankTimePeriodFirstMonth} to ${bank.bankTimePeriodLastMonth} Account No.( ${bank.accountNumber} )`}</span>
                  <br />
                  {matchedFile ? (
                    <>
                      {matchedFile.files.map((file, index) => {
                        return (
                          <div
                            className="mt-3 upload-lable"
                            style={{}}
                            key={index}
                          >
                            <FaCheck
                              className=""
                              style={{
                                marginTop: "0px !important",
                              }}
                            />

                            <span
                              style={{
                                fontSize: "13px",
                                marginTop: "3px",
                              }}
                            >
                              {file.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <FaExclamationCircle
                        className=""
                        style={{
                          color: "rgb(255, 187, 0)",
                          marginTop: "2px !important",
                        }}
                      />
                      <span className=""> Not Uploaded</span>
                    </>
                  )}
                </div>
                <div className="col-4 col-md-2 mt-0 mt-md-4">
                  <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={() => handleUploadButtonStatement(bank)}
                  >
                    {matchedFile ? "Edit" : "Upload"}
                  </Button>
                  <Dialog
                  className="p-1 rounded"
                  open={isPopupOpen1}
                  onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                      handleDialogClose1();
                    }
                  }}
                  disableEscapeKeyDown
                >
                  <div className="border border-dark m-2 rounded">
                    <DialogContent>
                      <DialogContentText className="mt-4 mb-2 fw-bold text-dark">
                        Applicant Details are added successfully.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <ArthaContinueButton
                        className="mx-auto mb-4"
                        onClick={handleDialogClose1}
                        autoFocus
                        style={{ width: "37%", height: "6vh" }}
                      >
                        OK
                      </ArthaContinueButton>
                    </DialogActions>
                  </div>
                </Dialog>
                </div>
              </div>
            );
          })}

        <UploadBankStatementPopup
          open={isPopupOpen}
          handleClose={handlePopupClose}
          popupLable="Bank Statement"
          bank={selectedBank}
          removeFileFromFileNames={removeFileFromFileNames}
          fileNames={fileNames}
          setFileNames={setFileNames}
        />
      </div>

      <div className="row mt-5 ">
        <div className="col-6 d-flex justify-content-end">
          <ArthaClearButton
            type="button"
            className="custom_btn"
            onClick={handleBack}
          >
            Back
          </ArthaClearButton>
        </div>
        <div className="col-6 d-flex justify-content-start">
          <ArthaContinueButton
            type="button"
            className="orange custom_btn"
            onClick={handleContinue}
            style={{
              opacity: isButtonClicked ? 0.39 : 1,
              pointerEvents: isButtonClicked ? "none" : "auto",
            }}
          >
            {isLoadingbtn ? (
              <LoadingSpinner style={{ pointerEvents: "none" }} />
            ) : (
              "Submit Application"
            )}
          </ArthaContinueButton>
        </div>
      </div>
    </>
  );
};
export default BankStatement;
