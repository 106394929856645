import React, { useEffect, useState, useRef } from "react";
import "../LoanAgainstProperty/personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField.js";
import PinCodeValidation from "./pinCodeValidation.jsx";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import {
  propertyLoanUpdatePersonalDetailsAPI,
  propertyLoanUdyamVerificationAPI,
  propertyLoanFetchUserDetails, propertyLoanFetchDataAPI
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";

const PersonalAndBusinessDetailsNoPANForm = ({ onSubmit, onCancel, changeStatus,changeApplicantStatus }) => {
  const [formData, setFormData] = useState({
    businessPan: '',
    pan: "",
    mobile: "",
    fullName: "",
    employmentType: "Self-Employed Business",
    annualTurnover: "",
    dateOfBirth: "",
    pinCode: "",
    emailId: "",
    currentBusinessPincode: "",
    udyamAadhaar: "",
    businessPan: "",
    businessName: "",
    commencementDate: "",
    businessId: "",
  });
  const [tenureDropdown, setTenureDropdown] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [isValidBusinessPan, setIsValidBusinessPan] = useState(true);
  const [isValidDOB, setIsValidDOB] = useState(true);
  const [isValidCommencementDate, setisValidCommencementDate] = useState(true);
  const navigate = useNavigate();
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [loading, setLoading] = useState(true);
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [udyamError, setUdyamError] = useState(false);
  const [pinCodeError, setPinCodeError] = useState("");
  const [pinCodeError1, setPinCodeError1] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [businessId, setBusinessId] = useState(false);
  const [isUdyamValid, setIsUdyamValid] = useState(false); // State variable to track Udyam regex validity
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const fetchTenureValues = async () => {
    setTenureDropdown([]);
    try {
      let payload = {
        source: "json_value",
        key: "TENURE_MONTHS",
        type: "LAP_LOAN",
      };
      const response = await propertyLoanFetchDataAPI(payload);
      setTenureDropdown(response?.data?.[0]?.values);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  useEffect(() => {
    fetchTenureValues();
  }, []);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await propertyLoanFetchUserDetails();
        const {
          mobile,
          // pan,
          fullName,
          email,
          dob,
          pinCode,
          businessId,
          dateOfCommencement,
          BusinessPan,
          firmName,
          udyamNumber,
          businessPinCode,
          annualTurnover
        } = response.data;

        // Use a default value if annualTurnover is not provided
        const formattedAnnualTurnover = annualTurnover != null ? Math.floor(annualTurnover).toString() : "";

        setFormData((prevData) => ({
          ...prevData,
          // pan: pan,
          mobile: mobile,
          fullName: fullName,
          dateOfBirth: formatDate(dob),
          emailId: email,
          pinCode: pinCode,
          businessId: businessId,
          commencementDate: dateOfCommencement,
          businessPan: BusinessPan,
          currentBusinessPincode: businessPinCode,
          businessName: firmName,
          udyamAadhaar: udyamNumber,
          annualTurnover: formattedAnnualTurnover,
        }));
        setBusinessId(businessId);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();

  }, [ap_id, loan_app_id]);

  const formatDate = (inputDate) => {
    console.log("input date : ", inputDate);
    if (inputDate) {
      const [day, month, year] = inputDate.split("/");
      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return "";
      }

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const businesspanRegex = /^[A-Z]{3}[F|T|H|P|C]{1}[A-Z]{1}\d{4}[A-Z]{1}$/gm;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsUserInteracted(true);
    // Apply specific validation based on the field name
    let updatedValue = value;
    if (name === "udyamAadhaar") {
      const updatedValue = value.trim().toUpperCase(); // Trim leading/trailing spaces and convert to uppercase
      const isUdyamValid = /^UDYAM-[A-Za-z]{2}-\d{2}-\d{7}$/.test(updatedValue);
      if (!isUdyamValid) {
        setUdyamError(
          "Invalid Udyam Number. Please enter in the specified format."
        );
      } else {
        setUdyamError("");
      }
      if (!updatedValue) {
        // Set businessName to null if udyamAadhaar is empty
        setFormData((prevData) => ({
          ...prevData,
          udyamAadhaar: updatedValue,
          businessName: "",
          commencementDate: "",
        }));
        return;
      }
    }
    if (name === "pan") {
      // Validate Pan card format: First 5 letters, 4 digits, 1 letter
      updatedValue = value.toUpperCase();
      const enteredPanCard = updatedValue;
      const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      setIsValidPanCard(panCardRegex.test(enteredPanCard));
    } else if (name === "fullName") {
      // You might want to discuss this validation with stakeholders
      updatedValue = value.replace(/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, "");
    } else if (name === "mobile") {
      updatedValue = value.replace(/\D/g, "");
    } else if (name === "currentBusinessPincode") {
      updatedValue = value.replace(/\D/g, "").slice(0, 6);
      const isPinCodeValid = /^\d{6}$/.test(updatedValue);
      if (!isPinCodeValid) {
        setPinCodeError("Pincode must be 6 digits");
      } else {
        setPinCodeError("");
      }
    } else if (name === "pinCode") {
      updatedValue = value.replace(/\D/g, "").slice(0, 6);
      const isPinCodeValid1 = /^\d{6}$/.test(updatedValue);
      if (!isPinCodeValid1) {
        setPinCodeError1("Pincode must be 6 digits");
      } else {
        setPinCodeError1("");
      }
    }
    else if (name === "emailId") {
      const isEmailValid =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);

      if (!isEmailValid) {
        setEmailError("Invalid Email. Please enter a valid email address.");
      } else {
        setEmailError("");
      }

      setIsEmailValid(isEmailValid);
    } else if (name === "dateOfBirth") {
      const [year, month, day] = value.split("-");
      const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);

      if (!isValidDate) {
        console.error("Invalid date format");
        // Handle error gracefully, maybe set an error state
        return;
      }

      updatedValue = `${year}-${month}-${day}`;
    } else if (name === 'businessPan') {
      // Convert to uppercase first, then remove special characters
      updatedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
      // Limit the input to 10 characters
      if (updatedValue.length > 10) {
        updatedValue = updatedValue.slice(0, 10);
      }
      setIsValidBusinessPan(value === '' || businesspanRegex.test(updatedValue));
    } else if (name === 'commencementDate') {
      const currentDate = new Date();
      const hundredYearsAgo = currentDate.getFullYear() - 100;

      // Convert the date value from YYYY-MM-DD to a Date object
      const enteredDate = new Date(value);
      const enteredYear = enteredDate.getFullYear();

      // Check if the year is within the last 100 years
      const isValidYear = enteredYear >= hundredYearsAgo && enteredYear <= currentDate.getFullYear();

      // Check if the date is valid
      const isValidDate = !isNaN(enteredDate.getTime()) && enteredDate.toISOString().split('T')[0] === value;

      // Check if the entered date is in the future
      const isFutureDate = enteredDate > currentDate;

      // Combine all validation checks
      if (!isValidDate || !isValidYear || isFutureDate) {
        setisValidCommencementDate(false);
      } else {
        setisValidCommencementDate(true);
      }
    }

    if (name === "annualTurnover") {
      const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const regex = /^[0-9]{0,8}$/; // Only allow up to 8 digits

      if (regex.test(sanitizedValue)) {
        updatedValue = sanitizedValue; // Update the value with sanitized input
      } else {
        console.error("Invalid input. Please enter a number with up to 8 digits.");
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
      //...additionalUpdates,
    }));
    // validateForm(); // Make sure validateForm() is defined elsewhere
  };

  const validateForm = () => {
    const {
      // pan,
      mobile,
      fullName,
      dateOfBirth,
      emailId,
      currentBusinessPincode,
      udyamAadhaar,
      pinCode,
      businessName,
      commencementDate,
      businessPan,
      annualTurnover,
      employmentType,
    } = formData;
    const isUdyamValid = /^UDYAM-[A-Za-z]{2}-\d{2}-\d{7}$/.test(udyamAadhaar);
    const isAnnualTurnoverValid = /^\d+$/.test(annualTurnover);
    // const isPanCardValid = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(pan);
    const isMobileValid = /^\d{10}$/.test(mobile);
    const isFullNameValid = fullName && fullName.trim() !== "";
    const isEmployentTypeValid = employmentType && employmentType.trim() !== "";
    let isDateOfBirthValid = false;
    const isEmailValid = /^\S+@\S+\.\S+$/.test(emailId);
    const isPinCodeValid = /^\d{6}$/.test(currentBusinessPincode);
    const isPinCodeValid1 = /^\d{6}$/.test(pinCode);

    let isBusinessPanCardValid = true;
    if (businessPan && businessPan.trim() !== "") {
      isBusinessPanCardValid = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(businessPan);
    }
    const currentDate = new Date();
    const selectedDate = new Date(dateOfBirth);
    const minDate = new Date(currentDate);
    minDate.setFullYear(minDate.getFullYear() - 100);
    const maxDate = new Date(currentDate);
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    if (selectedDate < minDate || selectedDate > maxDate) {
      isDateOfBirthValid = false;
      setIsValidDOB(false);
    } else {
      isDateOfBirthValid = true;
      setIsValidDOB(true);
    }

    setIsFormValid(
      isUdyamValid &&
      // isPanCardValid &&
      isMobileValid &&
      isFullNameValid &&
      isDateOfBirthValid &&
      isAnnualTurnoverValid &&
      isPinCodeValid1 &&
      isEmailValid &&
      isEmployentTypeValid &&
      isBusinessPanCardValid &&
      isPinCodeValid &&
      businessName &&
      commencementDate &&
      isValidBusinessPan &&// Check for Business PAN validity
      isValidCommencementDate
    );
  };
  const handleClear = () => {
    changeApplicantStatus("");
  };

  useEffect(() => {
    const pinCodeInput = document.getElementById("pin-code-inpt");
    if (pinCodeInput) {
      pinCodeInput.focus();
    }
  }, []);

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor:
      isFormValid && isEmailValid
        ? "rgba(255, 115, 0, 1)"
        : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const clearButtonStyle = {
    color: "black",
    backgroundColor: isFormValid
      ? "rgba(255, 255, 255, 1)"
      : "rgb(255 255 255 / 39%)",
  };

  const handleContinue = async (values, setSubmitting) => {
    // changeStatus("");
    setIsButtonClicked(true);
    setIsLoading(true);
    const formattedDateOfBirth = reverseFormatDate(formData.dateOfBirth);
    const fromatedcommecementDate = reverseFormatDate(
      formData.commencementDate
    );
    const data = {
      // panCardNo: formData.pan,
      mobileNo: formData.mobile,
      fullName: formData.fullName,
      dob: formattedDateOfBirth,
      email: formData.emailId,
      pinCode: formData.pinCode,
      employmentType: formData.employmentType,
      udhyamAadhaar: formData.udyamAadhaar,
      businessPan: formData.businessPan,
      businessName: formData.businessName,
      dateOfCommencement: fromatedcommecementDate,
      currentBusinessPincode: formData.currentBusinessPincode,
      annualTurnover: formData.annualTurnover,

    };
    try {
      const response = await propertyLoanUpdatePersonalDetailsAPI(data);
      console.log(
        "response.businessStatusCode",
        response.data.businessStatusCode
      );
      if (response.data.businessStatusCode === 2) {
        // setShowEligibilityCheckForm(true);    
         changeApplicantStatus(response?.data?.nextPage);
        // toast.success("Applicant Details Added Successfully");
      } else if (
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.1
      ) {
        toast.info(response.data.message);
      } else if (
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.3
      ) {
        toast.warning(response.data.message);
        navigate("/home");
      } else {
        setShowEligibilityCheckForm(false);
        setIsOpen(true);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const reverseFormatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const closeModal = () => {
    setIsOpen(false);
    let inpt = document.getElementById("pin-code-inpt");
    inpt.focus();
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const [isUserInteracted, setIsUserInteracted] = useState(false);


  const handleBlur = async () => {
    if (isUserInteracted && formData.udyamAadhaar && !udyamError) {
      setIsButtonClicked(true);
      setIsLoading(true);
      const requestBody = {
        udyamNumber: formData.udyamAadhaar,
        businessId: formData.businessId,
      };

      try {
        const response = await propertyLoanUdyamVerificationAPI(requestBody);
        const { businessName, commencementDate, businessPan } = response.data;

        setFormData((prevData) => ({
          ...prevData,
          commencementDate: formatDate(commencementDate),
          businessPan: businessPan,
          businessName: businessName,
        }));
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching loan data:", error);
      } finally {
        setIsLoading(false);
        setIsButtonClicked(false);
      }
    }
  };

  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };

  return (
    <>
     
        <>
        <div className="d-flex justify-content-between align-items-center my-3">
          <h6 className="text">Personal Details</h6>
          <div
          onClick={goToSummaryPage}
          className="text fw-bold"
          style={{
            color: "orange",
            marginLeft: "auto",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Go To Applicant Summary
        </div>
        </div>

          <form className="mt-4">
            <div className="row mt-4">
              {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="pan"
                  // value={formData.pan}
                  // onChange={handleChange}
                  disabled
                  label="Pan Card No.(Optional)"
                  fullWidth
                  variant="outlined"
                  className="form-control custom-disabled-input"
                />

                <div className="subtitle">
                  <div
                    className={`pan-card-validation ${isValidPanCard ? "" : "invalid-input"
                      }`}
                  >
                    {isValidPanCard
                      ? ""
                      : "Invalid Pan Card. Please enter in the specified format."}
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  label="Mobile No."
                  fullWidth
                  className="form-control custom-disabled-input"
                  disabled
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  label="Full Name"
                  fullWidth
                  className="form-control custom-disabled-input"
                  inputProps={{ maxLength: 80 }}
                />
                <p className="msg pt-2">As per PAN</p>
              </div>

            </div>

            <div className="row mt-1">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2 mt-md-0">
                <ArthaTextField
                  type="date"
                  name="dateOfBirth"
                  label="Date of birth"
                  className="form-control custom-disabled-input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  inputProps={{
                    max: currentDate, // Set max date to current date
                  }}
                  fullWidth
                />
                {!isValidDOB && (
                  <p className="msg" style={{ color: "red" }}>
                    Invalid date
                  </p>
                )}
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  label="Email"
                  fullWidth
                  error={Boolean(emailError)}
                  helperText={emailError}
                />
              </div>
            </div>

            <div className="row mt-4">
             
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="pinCode"
                  type="text"
                  maxLength="6"
                  value={formData.pinCode}
                  onChange={handleChange}
                  label="Pincode of your current address"
                  id="pin-code-inpt"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(pinCodeError1)}
                  helperText={pinCodeError1}
                  autoFocus
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 ">
                <ArthaTextField
                  name="employmentType"
                  value={formData.employmentType}
                  disabled
                  onChange={handleChange}
                  label="Employment Type"
                  fullWidth
                  className="form-control custom-disabled-input"
                  inputProps={{ maxLength: 80, shrink: true }}
                />
              </div>
            </div>

            {/* <div className="row mt-4">
           
            </div> */}
            <h6 className="text mt-4">Basic Business Details</h6>

            <div className="row mt-4">
              <div className="row mt-3 mt-md-2">
                <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0"></div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="udyamAadhaar"
                  value={formData.udyamAadhaar}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Udyam Aadhaar"
                  placeholder="UDYAM-XX-XX-XXX720"
                  fullWidth
                  error={Boolean(udyamError)}
                  helperText={udyamError}
                  autoFocus
                  inputProps={{ maxLength: 19 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ fontSize: "12px" }}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
                <ArthaTextField
                  id="outlined-basic"
                  name="businessPan"
                  value={formData.businessPan}
                  onChange={handleChange}
                  label="Business PAN (Optional for Proprietary)"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className="form-control"
                />
                <div
                  className={`pan-card-validation ${isValidBusinessPan ? "" : "invalid-input"
                    }`}
                >
                  {!isValidBusinessPan && (
                    <span style={{ color: "red", marginTop: "8px", display: "inline-block" }}>
                      Invalid Business Pan format. Please enter in the specified format.
                    </span>
                  )}
                </div>

              </div>
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  label="Business Name"
                  fullWidth
                  className="form-control custom-disabled-input"
                  disabled
                  inputProps={{ maxLength: 80 }}
                />
                {/* <p className="msg">As per PAN</p> */}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="date"
                  name="commencementDate"
                  label="Date of Commencement"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.commencementDate}
                  onChange={handleChange}
                  inputProps={{
                    max: currentDate,
                  }}
                  fullWidth
                />
                {!isValidCommencementDate && (
                  <p className="msg" style={{ color: "red" }}>
                    Invalid date
                  </p>
                )}
              </div>
            </div>

            <div className="row mt-3 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-3">
                <ArthaTextField
                  name="currentBusinessPincode"
                  type="text"
                  maxLength="6"
                  value={formData.currentBusinessPincode}
                  onChange={handleChange}
                  label="Current Business Pincode"
                  id="pin-code-inpt"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(pinCodeError)}
                  helperText={pinCodeError}
                  autoFocus
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-3">
                <ArthaTextField
                  id="outlined-basic"
                  name="annualTurnover"
                  label="₹ Annual Turnover"
                  fullWidth
                  variant="outlined"
                  value={formData.annualTurnover}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                <ArthaClearButton
                  variant="contained"
                  onClick={handleClear}
                >
                  Cancel
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start mt-4">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  style={buttonStyle}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
            <PinCodeValidation isOpen={isOpen} closeModal={closeModal} />
          </form>
        </>

    </>
  );
};

export default PersonalAndBusinessDetailsNoPANForm;
