import React, { useEffect, useState, useRef } from "react";
import PhotoVerification from "./PhotoVerification";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessLoanAddNewPopUp from "./AddNewPopUp";
import EditAddressPopUp from "./EditAddressPopup";
import { handleSignOut } from "./SessionExpiredApi";
import AdharOTPVerification from "./AdharOTPVerification";
import { propertyLoanFetchAddressAPI,propertyLoanBackPageAPI,propertyLoanFetchAddressVintageAPI, propertyLoanStoreAddressDetailsAPI } from "../../actioncreators/actioncreators";
import LoadingSpinner from "../../fields/spinner";
import { toast } from 'react-toastify';

export default function KYCDetailsForm({ changeApplicantStatus}) {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);
  const [isPermanentAddressProofVerified, setIsPermanentAddressProofVerified] =
    useState(false);
  const [isCurrentAddressProofVerified, setIsCurrentAddressProofVerified] =
    useState(false);
  const navigate = useNavigate();
  const [addressResponse, setAddressResponse] = useState([]);

  const [currentAddressResponse, setCurrentAddressResponse] = useState([]);

  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [popupLable, setPopupLable] = useState("");
  const { ap_id, loan_app_id } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  // State for permanent address fields
  const [selectedPermanentAddress, setSelectedPermanentAddress] = useState("");
  const [selectedCurrentAddress, setSelectedCurrentAddress] = useState("");
  const [permanentAddressLine1, setPermanentAddressLine1] = useState("");
  const [permanentAddressLine2, setPermanentAddressLine2] = useState("");
  const [permanentPincode, setPermanentPincode] = useState("");
  const [permanentCity, setPermanentCity] = useState("");
  const [permanentState, setPermanentState] = useState("");
  const [permanentResidenceOwner, setPermanentResidenceOwner] = useState("");
  const [permanentResidenceDuration, setPermanentResidenceDuration] =
    useState("");

  // State for current address fields
  const [currentAddressLine1, setCurrentAddressLine1] = useState("");
  const [currentAddressLine2, setCurrentAddressLine2] = useState("");
  const [currentPincode, setCurrentPincode] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [currentResidenceOwner, setCurrentResidenceOwner] = useState("");
  const [currentResidenceDuration, setCurrentResidenceDuration] = useState("");
  const [currentAddresses, setCurrentAddresses] = useState([]);
  const [permanentAddresses, setPermanentAddresses] = useState([]);
  const [addressProofForAddress,setAddressProofForAddress] = useState([]);

  const [permanentAddress, setPermanentAddress] = useState("permanantAddress1");
  const [currentAddress, setCurrentAddress] = useState("currentAddress1");

  const [showAddNewCurrentAddressForm, setShowAddNewCurrentAddressForm] =
    useState(false);

  const [showAddNewPermenentAddressForm, setShowAddNewPermenentAddressForm] =
    useState(false);

  const toggleAddNewCurrentAddressForm = () => {
    setCurrentAddress(null);
    setShowAddNewCurrentAddressForm(!showAddNewCurrentAddressForm);
  };

  const toggleAddNewPermenentAddressForm = () => {
    // Deselect the selected permanent address
    setPermanentAddress(null);

    // Toggle the visibility of the form
    setShowAddNewPermenentAddressForm(!showAddNewPermenentAddressForm);
  };

  const [permenantNewAddressCount, setPermenantNewAddressCount] =
    React.useState(0);
  const [currentNewAddressCount, setCurrentNewAddressCount] = React.useState(0);
  const closeModal = () => {
    setIsOpen(false);
    setPermenantNewAddressCount(permenantNewAddressCount - 1);
  };
  const closeAfterSave = (type) => {
    setIsOpen(false);
    if (type == 'Permenent') {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  }
  const closeCurrentAddressModal = (type) => {
    if (type == 'Permenent') {
      setIsOpen(false);
      setPermenantNewAddressCount(permenantNewAddressCount - 1);
    } else {
      setIsOpen(false);
      setCurrentNewAddressCount(currentNewAddressCount - 1);
    }
  };
  const openPopupForCurrent = () => {
    if (currentNewAddressCount < 1) {
      setPopupLable("Current");
      setIsOpen(true);
      setFormData({
        ...formData,
        propertyOwnershipCorrespondingAddress: "",
        residenceVintageCorrespondingAddress: "",
      });
      setCurrentNewAddressCount(currentNewAddressCount + 1);
    }
  };
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const openPopupForPermenent = () => {
    if (permenantNewAddressCount < 1) {
      setPopupLable("Permenent");
      setIsOpen(true);
      setFormData({
        ...formData,
        propertyOwnershipPermanentAddress: "",
        residenceVintagePermanentAddress: "",
      });
      setPermenantNewAddressCount(permenantNewAddressCount + 1);
    }
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
  };

  const [editPermAddressData, setEditPermAddressData] = React.useState({});
  const openPopupForEditPermenent = (data) => {
    setPopupLable("Permenent");
    setIsEditOpen(true);
    setEditPermAddressData(data);
  };

  const openPopupForEditCurrent = (data) => {
    setPopupLable("Current");
    setIsEditOpen(true);
    setEditPermAddressData(data);
  };

  const addPermanentAddress = (newAddress) => {
    if (popupLable === "Permenent") {
      newAddress.selectedByUser = true;
      setPermanentAddresses([newAddress]);
      addressResponse.map((item) => {
        item.selectedByUser = false;
      });
      setSelectedPermanentAddress("newPermenant");
    } else {
      newAddress.selectedByUser = true;
      setCurrentAddresses([newAddress]);
      currentAddressResponse.map((item) => {
        item.selectedByUser = false;
      });
      setSelectedCurrentAddress("newCurrent");
    }
  };

  const document = (newDocument) => {
    setAddressProofForAddress([newDocument]);
  }

console.log("document",document);
  const handleFileChangePermanent = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected permanent address file:", file);
      setIsPermanentAddressProofVerified(true);
    } else {
      setIsPermanentAddressProofVerified(false);
    }
  };

  const handleDeleteNewPermenentAddress = () => {
    const updatedAddresses = [];
    setPermanentAddresses(updatedAddresses);
    setSelectedPermanentAddress("");
    setPermenantNewAddressCount(permenantNewAddressCount - 1);
  };

  const handleDeleteNewCurrentAddress = () => {
    const updatedAddresses = [];
    setCurrentAddresses(updatedAddresses);
    setSelectedCurrentAddress("");
    setCurrentNewAddressCount(currentNewAddressCount - 1);
  };

  const handleFileChangeCurrent = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected current address file:", file);
      setIsCurrentAddressProofVerified(true);
    } else {
      setIsCurrentAddressProofVerified(false);
    }
  };
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 3 seconds
  };

  const handleSameAsPresentAddressChange = () => {
    setSameAsPresentAddress(!sameAsPresentAddress);

    if (!sameAsPresentAddress) {

      setFormData({
        ...formData,
        propertyOwnershipCorrespondingAddress:
          formData.propertyOwnershipPermanentAddress,
        residenceVintageCorrespondingAddress:
          formData.residenceVintagePermanentAddress
      })
      setCurrentAddressLine1(permanentAddressLine1);
      setCurrentAddressLine2(permanentAddressLine2);
      setCurrentPincode(permanentPincode);
      setCurrentCity(permanentCity);
      setCurrentState(permanentState);
      setCurrentResidenceOwner(permanentResidenceOwner);
      setCurrentResidenceDuration(permanentResidenceDuration);
      currentAddresses.map((item) => {
        item.selectedByUser = false;
      });
      currentAddressResponse.map((item) => {
        item.selectedByUser = false;
      });
    } else {
      if ((currentAddresses && currentAddresses.length == 0) || (currentAddressResponse && currentAddressResponse.length == 0)) {
        setSelectedCurrentAddress("");
      }
      currentAddresses.map((item) => {
        item.selectedByUser = false;
      });
      currentAddressResponse.map((item) => {
        item.selectedByUser = false;
      });
      setCurrentAddressLine1("");
      setCurrentAddressLine2("");
      setCurrentPincode("");
      setCurrentCity("");
      setCurrentState("");
      setCurrentResidenceOwner("");
      setCurrentResidenceDuration("");
    }
  };
  const [showAdharOTPVerification, setShowAdharOTPVerification] =
    useState(false);

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
        if (response?.data?.businessStatusCode === 2) {
          changeApplicantStatus(response?.data?.nextPage);
        } else {
          toast.info(response.data.message);
        }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const [maritalStatus, setMaritalStatus] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [religion, setReligion] = useState("");
  const [gender, setGender] = useState("");
  const [cityVintage, setCityVintage] = useState("");


  // const handleChange = ( event ) => {
  //   setFormData( { ...formData, maritalStatus: event.target.value.toUpperCase() } );
  //   setMaritalStatus( event.target.value );
  //   console.log( formData );
  // };

  const [formData, setFormData] = useState({
    name: name,
    gender: gender,
    maritalStatus: maritalStatus,
    residenceOwner: cityVintage,
    category: category,
    religion: religion
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyLoanFetchAddressAPI();
        const {
          addresses,
          name,
          gender,
          maritalStatus,
          cityVintage,
          category,
          religion,
          propertyOwnershipPermanentAddress,
          propertyOwnershipCorrespondingAddress,
          residenceVintagePermanentAddress,
          residenceVintageCorrespondingAddress,
        } = response.data;
        setName(name);
        setCategory(category);
        setReligion(religion);
        setGender(gender);
        setMaritalStatus(maritalStatus);
        setCityVintage(cityVintage);
        if (addresses) {
          setAddressResponse(JSON.parse(JSON.stringify(addresses)));
          setCurrentAddressResponse(JSON.parse(JSON.stringify(addresses)));
        }
        if (addresses) {
          setSelectedPermanentAddress("done");
          setSelectedCurrentAddress("done");
        }
        if (addresses) {
          for (let index = 0; index < addresses.length; index++) {
            const element = addresses[index];
            if (element.addressType == "BOTH" && element.selectedByUser) {
              setSameAsPresentAddress(true);
              for (let index = 0; index < currentAddresses.length; index++) {
                const element = currentAddresses[index];
                element.selectedByUser = false;
              }
              break;
            }
          }
        }
        setFormData({
          ...formData,
          name: name,
          religion: religion,
          category: category,
          gender: gender,
          maritalStatus: maritalStatus,
          residenceOwner: cityVintage,
          propertyOwnershipPermanentAddress: propertyOwnershipPermanentAddress,
          propertyOwnershipCorrespondingAddress:
            propertyOwnershipCorrespondingAddress,
          residenceVintagePermanentAddress:
            residenceVintagePermanentAddress &&
            residenceVintagePermanentAddress.replace(/yrs|\+ yrs|yr/g, "").trim(),
          residenceVintageCorrespondingAddress:
            residenceVintageCorrespondingAddress &&
            residenceVintageCorrespondingAddress.replace(/yrs|\+ yrs|yr/g, "").trim(),
        });
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching address data:", error);
      }
    };
    fetchData();
  }, [ap_id, loan_app_id, navigate]);

  const handleContinue = async () => {
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);
    let addresses = [];
    let sameAddressFlag = false;
    if (sameAsPresentAddress) {
      sameAddressFlag = true;
      const permanentAddress = addressResponse.find(
        (item) => item.addressId === selectedPermanentAddress
      );
      if (permanentAddress) {
        //existing
        addresses.push({
          addressId: permanentAddress.addressId,
          addressType: "BOTH",
          line1: permanentAddress.line1,
          line2: permanentAddress.line2,
          line3: permanentAddress.line3,
          city: permanentAddress.city,
          dist: permanentAddress.dist,
          state: permanentAddress.state,
          country: permanentAddress.country,
          pin: permanentAddress.pin,
        });
      } else {
        addresses.push({
          addressId: "",
          addressType: "BOTH",
          line1: permanentAddresses[0].addressLine1,
          line2: permanentAddresses[0].addressLine2,
          city: permanentAddresses[0].city,
          dist: permanentAddresses[0].dist,
          state: permanentAddresses[0].state,
          country: "India",
          pin: permanentAddresses[0].pincode,
          addressProofForAddress: addressProofForAddress,
        });
      }
    } else {
      //separate
      const permanentAddress = addressResponse.find(
        (item) => item.addressId === selectedPermanentAddress
      );
      console.log(permanentAddresses);
      if (permanentAddress) {
        addresses.push({
          addressId: permanentAddress.addressId,
          addressType: "PERMANENT",
          line1: permanentAddress.line1,
          line2: permanentAddress.line2,
          line3: permanentAddress.line3,
          city: permanentAddress.city,
          dist: permanentAddress.dist,
          state: permanentAddress.state,
          country: permanentAddress.country,
          pin: permanentAddress.pin,
          // Add other relevant details for the permanent address
        });
      } else {
        addresses.push({
          addressId: "",
          addressType: "PERMANENT",
          line1: permanentAddresses[0].addressLine1,
          line2: permanentAddresses[0].addressLine2,
          city: permanentAddresses[0].city,
          dist: permanentAddresses[0].dist,
          state: permanentAddresses[0].state,
          country: "India",
          pin: permanentAddresses[0].pincode,
          addressProofForAddress: addressProofForAddress,
        });
      }

      const currentAddress = addressResponse.find(
        (item) => item.addressId === selectedCurrentAddress
      );
      if (currentAddress) {
        addresses.push({
          addressId: currentAddress.addressId,
          addressType: "CURRENT",
          line1: currentAddress.line1,
          line2: currentAddress.line2,
          line3: currentAddress.line3,
          city: currentAddress.city,
          dist: currentAddress.dist,
          state: currentAddress.state,
          country: currentAddress.country,
          pin: currentAddress.pin,
          // Add other relevant details for the permanent address
        });
      } else {
        addresses.push({
          addressId: "",
          addressType: "CURRENT",
          line1: currentAddresses[0].addressLine1,
          line2: currentAddresses[0].addressLine2,
          city: currentAddresses[0].city,
          dist: currentAddresses[0].dist,
          state: currentAddresses[0].state,
          country: "India",
          pin: currentAddresses[0].pincode,
          addressProofForAddress: addressProofForAddress,
        });
      }
    }
    let data = {};
    if (sameAddressFlag) {
      data = {
        addresses: addresses,
        propertyOwnershipPermanentAddress:
          formData.propertyOwnershipPermanentAddress,
        propertyOwnershipCorrespondingAddress:
          formData.propertyOwnershipPermanentAddress,
        residenceVintagePermanentAddress:
          formData.residenceVintagePermanentAddress &&
          formData.residenceVintagePermanentAddress.replace(/yrs|\+ yrs|yr/g, "").trim(),
        residenceVintageCorrespondingAddress:
          formData.residenceVintagePermanentAddress &&
          formData.residenceVintagePermanentAddress.replace(/yrs|\+ yrs|yr/g, "").trim(),
        cityVintage:
          formData.residenceOwner &&
          formData.residenceOwner.replace(/yrs|\+ yrs|yr/g, "").trim(),
        // name: formData.name,
        gender: formData.gender,
        maritalStatus: formData.maritalStatus,
        religion: formData.religion,
        category: formData.category,
      };
    } else {
      data = {
        addresses: addresses,
        propertyOwnershipPermanentAddress:
          formData.propertyOwnershipPermanentAddress,
        propertyOwnershipCorrespondingAddress:
          formData.propertyOwnershipCorrespondingAddress,
        residenceVintagePermanentAddress:
          formData.residenceVintagePermanentAddress &&
          formData.residenceVintagePermanentAddress.replace(/yrs|\+ yrs|yr/g, "").trim(),
        residenceVintageCorrespondingAddress:
          formData.residenceVintageCorrespondingAddress &&
          formData.residenceVintageCorrespondingAddress.replace(
            /yrs|\+ yrs|yr/g,
            ""
          ).trim(),
        cityVintage:
          formData.residenceOwner &&
          formData.residenceOwner.replace(/yrs|\+ yrs|yr/g, "").trim(),
        // name: formData.name,
        gender: formData.gender,
        maritalStatus: formData.maritalStatus,
        religion: formData.religion,
        category: formData.category,
      };
    }

    console.log(data);

    // Define headers
    const headers = {
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };

    try {
      const response = await propertyLoanStoreAddressDetailsAPI(data);
        if (response.data.businessStatusCode === 2) {
          toast.success('KYC Details Added Successfully')
          changeApplicantStatus(response?.data?.nextPage);
        } else {
          toast.info(response.data.message);
        }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }

  };


  const [categoryOptions, setCategoryOptions] = useState([]);


  const fetchCategory = async () => {

    const apiPayload = {
      "type": "KYC_PAGE",
      "key": "CATEGORY",
      "source": "json_value",
    };
    try {
      const categoryData = await propertyLoanFetchAddressVintageAPI(apiPayload);
      console.log("categary response :", categoryData?.data?.data[0]?.source)
      setCategoryOptions(categoryData?.data?.data[0]?.source);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const categoryMap = categoryOptions?.map((category) => {
    return category;
  });


  const [communityOptions, setCommunityOptions] = useState([]);


  const fetchCommunity = async () => {

    const apiPayload = {
      "type": "KYC_PAGE",
      "key": "COMMUNITY",
      "source": "json_value",
    };
    try {
      const communityData = await propertyLoanFetchAddressVintageAPI(apiPayload);
      console.log("categary response :", communityData?.data?.data[0]?.source)
      setCommunityOptions(communityData?.data?.data[0]?.source);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
    useState(true);

  const validateForm = () => {
    const isGenderFilled = formData.gender && formData.gender.trim() !== "";
    const isMaritalStatusFilled =
      formData.maritalStatus && formData.maritalStatus.trim() !== "";
    const isResidenceOwnerFilled =
      formData.residenceOwner && formData.residenceOwner.trim() !== "";
    const isPermanentAddressSelected = selectedPermanentAddress !== "";
    const isCurrentAddressSelected = selectedCurrentAddress !== "";
    const isCommunityFilled =
      formData.religion && formData.religion.trim() !== "";
    const isCategoryFilled =
      formData.category && formData.category.trim() !== "";
    // Include validation for propertyOwnershipPermanentAddress and residenceVintagePermanentAddress
    const isPropertyOwnershipPermanentAddressFilled =
      formData.propertyOwnershipPermanentAddress &&
      formData.propertyOwnershipPermanentAddress.trim() !== "";
    const isResidenceVintagePermanentAddressFilled =
      formData.residenceVintagePermanentAddress &&
      formData.residenceVintagePermanentAddress.trim() !== "";
    let isPropertyOwnershipCorrespondingAddressFilled = false;
    let isPropertyVintageCorrespondingAddressFilled = false;
    if ((currentAddresses && currentAddresses.length > 0) || (currentAddressResponse && currentAddressResponse.length > 0)) {
      isPropertyOwnershipCorrespondingAddressFilled =
        formData.propertyOwnershipCorrespondingAddress &&
        formData.propertyOwnershipCorrespondingAddress.trim() !== "";
      isPropertyVintageCorrespondingAddressFilled =
        formData.residenceVintageCorrespondingAddress &&
        formData.residenceVintageCorrespondingAddress.trim() !== "";
    }
    if ((currentAddresses && currentAddresses.length > 0) || (currentAddressResponse && currentAddressResponse.length > 0)) {
      setIsContinueButtonDisabled(
        !(
          isGenderFilled &&
          isCategoryFilled &&
          isCommunityFilled &&
          isMaritalStatusFilled &&
          isResidenceOwnerFilled &&
          isPermanentAddressSelected &&
          isPropertyOwnershipCorrespondingAddressFilled &&
          isPropertyVintageCorrespondingAddressFilled &&
          (sameAsPresentAddress || isCurrentAddressSelected) &&
          isPropertyOwnershipPermanentAddressFilled &&
          isResidenceVintagePermanentAddressFilled
        )
      );
    } else {
      setIsContinueButtonDisabled(
        !(
          isGenderFilled &&
          isCategoryFilled &&
          isCommunityFilled &&
          isMaritalStatusFilled &&
          isResidenceOwnerFilled &&
          isPermanentAddressSelected &&
          (sameAsPresentAddress || isCurrentAddressSelected) &&
          isPropertyOwnershipPermanentAddressFilled &&
          isResidenceVintagePermanentAddressFilled
        )
      );
    }
  };

  useEffect(() => {
    findSelectedPermanentAddress();
    validateForm();
  }, [
    formData,
    selectedPermanentAddress,
    selectedCurrentAddress,
    sameAsPresentAddress,
  ]);

  useEffect(() => {
    if (addressResponse.length > 0) {
      let selectedItems = addressResponse.filter(
        (item) => item.selectedByUser && item.addressType == "PERMANENT"
      );
      if (selectedItems.length <= 0) {
        selectedItems = addressResponse.filter(
          (item) => item.selectedByUser && item.addressType == "BOTH"
        );
      }

      setSelectedPermanentAddress(
        selectedItems.length > 0 && selectedItems[0].addressId
      );

      let selectedCurrentItems = addressResponse.filter(
        (item) => item.selectedByUser && item.addressType == "CURRENT"
      );
      if (selectedCurrentItems.length <= 0) {
        selectedCurrentItems = addressResponse.filter(
          (item) => item.selectedByUser && item.addressType == "BOTH"
        );
      }

      setSelectedCurrentAddress(
        selectedCurrentItems.length > 0 && selectedCurrentItems[0].addressId
      );
    }
  }, [addressResponse]);



  const [addrVintage, setAddrVintage] = useState([]);

  const fetchAddressVintage = async () => {

    const apiPayload = {
      "type": "ADDRESS_VINTAGE",
      "key": "ADDRESS_VINTAGE",
      "source": "json_value",
    };
    try {
      const addressVintageRes = await propertyLoanFetchAddressVintageAPI(apiPayload);
      setAddrVintage(addressVintageRes.data.data[0].values);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const validExperiences = addrVintage.map((experience) => {
    return experience;
  });

  const residenceVintagePermanentAddressList = addrVintage.map((experience) => {
    return experience;
  });

  const residenceVintageCorrespondingAddressList = addrVintage.map((experience) => {
    return experience;
  });



  //MAPPING OF PROPERTYOWNER DROPDOWN FROM GENCONFIG
  const [propertyOwner, setPropertyOwner] = useState([]);
  useEffect(() => {

    fetchPropertyOwner();
    fetchAddressVintage();
    fetchCategory();
    fetchCommunity();
  }, [])
  const fetchPropertyOwner = async () => {

    const apiPayload = {
      "type": "ADDRESS_OWNER",
      "key": "ADDRESS_OWNER",
      "source": "json_value",
    };
    try {
      const addressPropertyOwner = await propertyLoanFetchAddressVintageAPI(apiPayload);
      setPropertyOwner(addressPropertyOwner.data.data[0].values);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching property owner:", error);
    }
  };

  const propertyOwnershipPermanentAddressList = propertyOwner.map((ownership) => {
    return ownership;
  });

  const propertyOwnershipCorrespondingAddressList = propertyOwner.map((ownership) => {
    return ownership;
  });


  const [genderList] = React.useState(["Male", "Female", "Other"]);
  const findSelectedPermanentAddress = () => {
    // Logic to find the selected permanent address
    // You might have a list like permanentAddresses or some other source
    // Use your own logic to find the selected permanent address
    const selectedPermanent = permanentAddresses.find(
      (address) => address.selectedByUser
    );

    return selectedPermanent ? selectedPermanent.addressId : null;
  };
  useEffect(() => {
    if (sameAsPresentAddress && selectedPermanentAddress) {
      setSelectedCurrentAddress(selectedPermanentAddress);
    }
  }, [sameAsPresentAddress, selectedPermanentAddress]);
  return (
    <>
      {!showAdharOTPVerification ? (
        <>
          <form className="kycform">
            <h6>KYC</h6>
            <p className="small-heading kyc-form-text-size">
              Following details are gathered from CKYC
            </p>

            <div className="row mt-4">
              <div className="col-12 col-md-6">
                <ArthaTextField
                  name="personName"
                  fullWidth
                  label="Name"
                  value={formData.name}
                //  onChange={handleNameChange}
                />
              </div>

              <div className="col-md-6 mt-3 mt-md-0">
                <ArthaFormControl fullWidth>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={(e) =>
                      setFormData({ ...formData, gender: e.target.value })
                    }
                  >
                    {genderList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ArthaFormControl>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-md-6 mt-3">
                <ArthaFormControl fullWidth>
                  <InputLabel id="religion-label">
                    Community
                  </InputLabel>
                  <Select
                    labelId="religion-label"
                    id="religion"
                    name="religion"
                    value={formData.religion}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        religion: e.target.value,
                      })
                    }
                  >
                    {communityOptions && communityOptions.map((item, index) => {
                      return (<MenuItem key={index} value={item}>{item}</MenuItem>)
                    })}
                  </Select>
                </ArthaFormControl>
              </div>

              <div className="col-12 col-md-6 mt-3">
                <ArthaFormControl fullWidth>
                  <InputLabel id="category-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="category-label"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        category: e.target.value,
                      })}>

                    {categoryMap && categoryMap.map((item, index) => {
                      return (<MenuItem key={index} value={item}>{item}</MenuItem>)
                    })}
                  </Select>
                </ArthaFormControl>
              </div>

            </div>

            <div className="row mt-4">

              <div className="col-12 col-md-6 mt-3">
                <ArthaFormControl fullWidth>
                  <InputLabel id="residenceOwner-label">
                    Residing in Current city Since
                  </InputLabel>
                  <Select
                    labelId="residenceOwner-label"
                    id="residenceOwner"
                    name="residenceOwner"
                    value={formData.residenceOwner}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        residenceOwner: e.target.value,
                      })
                    }
                  >
                    {validExperiences.map((item, index) => {
                      const numericValue = item.replace(/\D/g, '');
                      return (
                        <MenuItem key={index} value={numericValue}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ArthaFormControl>
              </div>
            </div>


            <div className="row mt-2">
              <div className="col-md-12">
                <div className="col-md-12 p-0">
                  <p className="small-heading kyc-form-text-size mt-2 mb-0">
                    Marital Status
                  </p>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <FormControlLabel
                      control={
                        <Radio
                          name="maritalStatus"
                          value="MARRIED"
                          checked={formData.maritalStatus === "MARRIED"}
                          onChange={(e) =>
                            setFormData({ ...formData, maritalStatus: "MARRIED" })
                          }
                          className={
                            formData.maritalStatus === "MARRIED" ? "radio-selected" : ""
                          }
                        />
                      }
                      label="Married"
                    />
                  </div>
                  <div className="col-auto">
                    <FormControlLabel
                      control={
                        <Radio
                          name="maritalStatus"
                          value="SINGLE"
                          checked={formData.maritalStatus === "SINGLE"}
                          onChange={(e) =>
                            setFormData({ ...formData, maritalStatus: "SINGLE" })
                          }
                          className={
                            formData.maritalStatus === "SINGLE" ? "radio-selected" : ""
                          }
                        />
                      }
                      label="Single"
                    />
                  </div>
                  <div className="col-auto">
                    <FormControlLabel
                      control={
                        <Radio
                          name="maritalStatus"
                          value="DIVORCED"
                          checked={formData.maritalStatus === "DIVORCED"}
                          onChange={(e) =>
                            setFormData({ ...formData, maritalStatus: "DIVORCED" })
                          }
                          className={
                            formData.maritalStatus === "DIVORCED" ? "radio-selected" : ""
                          }
                        />
                      }
                      label="Divorced"
                    />
                  </div>
                  <div className="col-auto">
                    <FormControlLabel
                      control={
                        <Radio
                          name="maritalStatus"
                          value="WINDOW"
                          checked={formData.maritalStatus === "WINDOW"}
                          onChange={(e) =>
                            setFormData({ ...formData, maritalStatus: "WINDOW" })
                          }
                          className={
                            formData.maritalStatus === "WINDOW" ? "radio-selected" : ""
                          }
                        />
                      }
                      label="Widow/Widower"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row mt-2 ">
              <div className="col-md-6">
                <div className="col-md-12 p-0">
                  <p className="small-heading kyc-form-text-size mt-2 mb-0">
                    Marital Status
                  </p>
                </div>
                <div className="row">
                  <div className="form-check col-6 col-md-6  mb-2">
                    <FormControlLabel
                      control={
                        <Radio
                          name="maritalStatus"
                          value="married"
                          checked={formData.maritalStatus === "MARRIED"}
                          onChange={( e ) =>
                            setFormData( { ...formData, maritalStatus: "MARRIED" } )
                          }
                          className={
                            formData.maritalStatus === "MARRIED"
                              ? "radio-selected"
                              : ""
                          }
                        />
                      }
                      label="Married"
                    />
                  </div>
                  <div className="form-check col-6 col-md-6">
                    <FormControlLabel
                      control={
                        <Radio
                          name="maritalStatus"
                          value="single"
                          checked={formData.maritalStatus === "SINGLE"}
                          onChange={( e ) =>
                            setFormData( { ...formData, maritalStatus: "SINGLE" } )
                          }
                          className={
                            formData.maritalStatus === "SINGLE"
                              ? "radio-selected"
                              : ""
                          }
                        />
                      }
                      label="Single"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 mt-3">
                <ArthaFormControl fullWidth>
                  <InputLabel id="residenceOwner-label">
                    Residing in Current city Since
                  </InputLabel>
                  <Select
                    labelId="residenceOwner-label"
                    id="residenceOwner"
                    name="residenceOwner"
                    value={formData.residenceOwner}
                    onChange={( e ) =>
                      setFormData( {
                        ...formData,
                        residenceOwner: e.target.value,
                      } )
                    }
                  >
                    {validExperiences.map( ( item, index ) => {
                      const numericValue = item.replace( /\D/g, '' );
                      return (
                        <MenuItem key={index} value={numericValue}>
                          {item}
                        </MenuItem>
                      );
                    } )}
                  </Select>
                </ArthaFormControl>
              </div>
            </div> */}

            <h6 className="kyc-form-text-size mt-5">Permanent Address</h6>
            {addressResponse && addressResponse.length > 0 &&
              <p className="small-heading kyc-form-text-size">
                We found multiple addresses linked to your account from CKYC. Select
                permanent address from the following or add new
              </p>}
            <div className="row ">
              {addressResponse &&
                addressResponse.map((item, index) => {
                  const isAddressSelectedPermanent =
                    selectedPermanentAddress === item.addressId ||
                    (item.selectedByUser && item.addressType == "PERMANENT");

                  return (
                    <div
                      className="form-check col-12 col-md-12 pl-0 mb-2"
                      key={index}
                    >
                      <div
                        className={`card shadow-none address-card ${isAddressSelectedPermanent ? "selected-card" : ""
                          }`}
                        style={{
                          marginBottom: "10px",
                          minHeight: "150px !important",
                        }}
                      >
                        <div className="card-body">
                          <FormControlLabel
                            control={
                              <input
                                type="radio"
                                value={item.addressId}
                                checked={isAddressSelectedPermanent}
                                onChange={(e) => {
                                  setSelectedPermanentAddress(e.target.value);
                                  setFormData({
                                    ...formData,
                                    propertyOwnershipPermanentAddress: "",
                                    residenceVintagePermanentAddress: ""
                                  });
                                  addressResponse.map((item) => {
                                    item.selectedByUser = false;
                                  });

                                  permanentAddresses.map((item) => {
                                    item.selectedByUser = false;
                                  });
                                  item.selectedByUser = true;
                                }}

                                style={{ marginRight: "14px" }}
                              />
                            }
                            label={[
                              item.line1,
                              item.line2,
                              item.line3,
                              item.city,
                              item.country,
                              item.pin,
                            ]
                              .filter((field) => field !== undefined)
                              .join(", ")}
                          />

                          {isAddressSelectedPermanent && (
                            <div className="row mt-10 ml-1">
                              <div className="col-md-6">
                                <ArthaFormControl fullWidth>
                                  <InputLabel id="permanentResidenceOwner-label">
                                    Residence ownership
                                  </InputLabel>
                                  <Select
                                    labelId="permanentResidenceOwner-label"
                                    id="permanentResidenceOwner"
                                    name="permanentResidenceOwner"
                                    value={
                                      formData.propertyOwnershipPermanentAddress
                                    }
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        propertyOwnershipPermanentAddress:
                                          e.target.value,
                                      })
                                    }
                                    onOpen={fetchPropertyOwner}
                                  >
                                    {propertyOwnershipPermanentAddressList.map(
                                      (item, index) => {

                                        return (<MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>); // This is necessary to avoid a warning about a missing return statement
                                      }
                                    )}
                                  </Select>
                                </ArthaFormControl>
                              </div>
                              <div className="col-md-6">
                                <ArthaFormControl fullWidth>
                                  <InputLabel id="permanentResidenceDuration-label">
                                    Residence Duration
                                  </InputLabel>
                                  <Select
                                    labelId="permanentResidenceDuration-label"
                                    id="permanentResidenceDuration"
                                    name="permanentResidenceDuration"
                                    value={
                                      formData.residenceVintagePermanentAddress
                                    }
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        residenceVintagePermanentAddress:
                                          e.target.value,
                                      })
                                    }
                                    onOpen={fetchAddressVintage}
                                  >
                                    {residenceVintagePermanentAddressList.map(

                                      (item, index) => {
                                        const numericValue = item.replace(/\D/g, '');
                                        return (
                                          <MenuItem key={index} value={numericValue}>
                                            {item}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </ArthaFormControl>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {permanentAddresses.map((address, index) => (
                <div key={index} className="form-check col-12 col-md-12 pl-0">
                  <div
                    className={`card shadow-none address-card ${address.selectedByUser ? "selected-card" : ""
                      }`}
                  >
                    <div class="card-body">
                      <div className="row">
                        <div className="col-8">
                          <FormControlLabel
                            control={
                              <input
                                type="radio"
                                checked={address.selectedByUser}

                              />
                            }
                            value={address.addressLine1}
                            onChange={(e) => {
                              setSelectedPermanentAddress(e.target.value);
                              setFormData({
                                ...formData,
                                propertyOwnershipPermanentAddress: "",
                                residenceVintagePermanentAddress: ""
                              });
                              addressResponse.map((item) => {
                                item.selectedByUser = false;
                              });

                              permanentAddresses.map((item) => {
                                item.selectedByUser = false;
                              });
                              address.selectedByUser = true;
                            }}
                            label={`${address.addressLine1}, ${address.addressLine2}, ${address.city} ${address.pincode} ${address.state}`}
                          />
                        </div>
                        <div className="col-4 d-flex justify-content-end">
                          {" "}
                          <span
                            className="editPopupLable"
                            onClick={() => {
                              openPopupForEditPermenent(address);
                            }}
                          >
                            Edit{" "}
                          </span>{" "}
                          <span
                            className="editPopupLable"
                            onClick={handleDeleteNewPermenentAddress}
                          >
                            Delete{" "}
                          </span>
                        </div>
                      </div>
                      {address.selectedByUser && (
                        <div className="row mt-10">
                          <div className="col-md-6">
                            <ArthaFormControl fullWidth>
                              <InputLabel id="permanentResidenceOwner-label">
                                Residence ownership
                              </InputLabel>
                              <Select
                                labelId="permanentResidenceOwner-label"
                                id="permanentResidenceOwner"
                                name="permanentResidenceOwner"
                                value={formData.propertyOwnershipPermanentAddress}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    propertyOwnershipPermanentAddress:
                                      e.target.value,
                                  })
                                }
                                onOpen={fetchPropertyOwner} //TODO
                              >
                                {propertyOwnershipPermanentAddressList.map(
                                  (item, index) => {
                                    return (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </ArthaFormControl>
                          </div>
                          <div className="col-md-6">
                            <ArthaFormControl fullWidth>
                              <InputLabel id="permanentResidenceDuration-label">
                                Residence Duration
                              </InputLabel>
                              <Select
                                labelId="permanentResidenceDuration-label"
                                id="permanentResidenceDuration"
                                name="permanentResidenceDuration"
                                value={formData.residenceVintagePermanentAddress}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    residenceVintagePermanentAddress:
                                      e.target.value,
                                  })
                                }
                                onOpen={fetchAddressVintage}
                              >
                                {residenceVintagePermanentAddressList.map(
                                  (item, index) => {
                                    const numericValue = item.replace(/\D/g, '');
                                    return (
                                      <MenuItem key={index} value={numericValue}>
                                        {item}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </ArthaFormControl>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-4 col-md-2">
                <Button
                  variant="contained"
                  className="upload_btn add-new-btn btn_white shadow-none mt-3"
                  onClick={openPopupForPermenent}
                  disabled={permenantNewAddressCount > 0}
                >
                  Add New
                </Button>
                <BusinessLoanAddNewPopUp
                  popupLable={popupLable}
                  isOpen={isOpen}
                  closeModal={closeModal}
                  onsaveClose={closeAfterSave}
                  addPermanentAddress={addPermanentAddress}
                  document={document}
                  data={editPermAddressData}
                />

                <EditAddressPopUp
                  popupLable={popupLable}
                  isOpen={isEditOpen}
                  closeModal={closeEditModal}
                  onsaveClose={closeAfterSave}
                  addPermanentAddress={addPermanentAddress}
                  data={editPermAddressData}
                  document={document}
                />
              </div>
            </div>

            <h6 className="mt-5 kyc-form-text-size">Current Address</h6>
            {(addressResponse && addressResponse.length > 0 || permanentAddresses && permanentAddresses.length > 0) &&
              <>
                <p className="small-heading kyc-form-text-size">
                  Select the current address from following or add new
                </p>
                <div className="row ">
                  <div className="col-md-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="currentAddress"
                          id="sameAsPresentAddress"
                          checked={sameAsPresentAddress}

                          onChange={handleSameAsPresentAddressChange}
                        />
                      }
                      label="Same as permanent address"
                    />
                  </div>
                </div>
              </>
            }

            <div className="row mt-2">
              {currentAddressResponse &&
                currentAddressResponse.map((item, index) => {
                  const isAddressSelectedCurrent =
                    selectedCurrentAddress === item.addressId ||
                    (item.selectedByUser && item.addressType === "CURRENT");

                  const isSameAsPermanentChecked =
                    sameAsPresentAddress && findSelectedPermanentAddress() === item.addressId;

                  return (
                    <div
                      key={index}
                      className="form-check col-12 col-md-12 pl-0 mb-2"
                    >
                      <div
                        className={`card shadow-none address-card ${isAddressSelectedCurrent || isSameAsPermanentChecked ? "selected-card" : ""
                          }`}
                        style={{
                          marginBottom: "10px",
                          minHeight: "150px",
                        }}
                      >
                        <div className="card-body">
                          <FormControlLabel
                            control={
                              <input
                                type="radio"
                                style={{ marginRight: "14px" }}
                                value={item.addressId}
                                checked={isAddressSelectedCurrent || isSameAsPermanentChecked}
                                onChange={(e) => {
                                  setSelectedCurrentAddress(e.target.value);
                                  setFormData({
                                    ...formData,
                                    propertyOwnershipCorrespondingAddress: "",
                                    residenceVintageCorrespondingAddress: ""
                                  });
                                  currentAddressResponse.map((item) => {
                                    item.selectedByUser = false;
                                  });
                                  currentAddresses.map((item) => {
                                    item.selectedByUser = false;
                                  });
                                  item.selectedByUser = true;
                                }}
                                disabled={sameAsPresentAddress}
                              />
                            }
                            label={[
                              item.line1,
                              item.line2,
                              item.line3,
                              item.city,
                              item.country,
                              item.pin,
                            ]
                              .filter((field) => field !== undefined)
                              .join(", ")}
                          />

                          {isAddressSelectedCurrent && (
                            <div className="row mt-10 ml-1">
                              <div className="col-md-6 ">
                                <ArthaFormControl fullWidth>
                                  <InputLabel id="currentResidenceOwner-label">
                                    Residence ownership
                                  </InputLabel>
                                  <Select
                                    labelId="currentResidenceOwner-label"
                                    id="currententResidenceOwner"
                                    name="currententResidenceOwner"
                                    value={
                                      formData.propertyOwnershipCorrespondingAddress
                                    }
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        propertyOwnershipCorrespondingAddress:
                                          e.target.value,
                                      })
                                    }
                                    disabled={sameAsPresentAddress}
                                  >
                                    {propertyOwnershipCorrespondingAddressList.map(
                                      (item, index) => (
                                        <MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </ArthaFormControl>
                              </div>
                              <div className="col-md-6">
                                <ArthaFormControl fullWidth>
                                  <InputLabel id="currentResidenceDuration-label">
                                    Residence Duration
                                  </InputLabel>
                                  <Select
                                    labelId="currentResidenceDuration-label"
                                    id="currentResidenceDuration"
                                    name="currentResidenceDuration"
                                    value={
                                      formData.residenceVintageCorrespondingAddress
                                    }
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        residenceVintageCorrespondingAddress:
                                          e.target.value,
                                      })
                                    }
                                    onOpen={fetchAddressVintage}
                                    disabled={sameAsPresentAddress}
                                  >
                                    {residenceVintageCorrespondingAddressList.map(
                                      (item, index) => {
                                        const numericValue = item.replace(/\D/g, '');
                                        return (<MenuItem key={index} value={numericValue}>
                                          {item}
                                        </MenuItem>)
                                      }
                                    )}
                                  </Select>
                                </ArthaFormControl>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );

                })}

              {currentAddresses.map((address, index) => (
                <div key={index} className="form-check col-12 col-md-12 pl-0">
                  <div
                    className={`card shadow-none address-card ${address.selectedByUser && !sameAsPresentAddress ? "selected-card" : ""
                      }`}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-8">
                          <FormControlLabel
                            control={<Radio />}
                            value={address.addressLine1}
                            checked={address.selectedByUser && !sameAsPresentAddress}
                            onChange={(e) => {
                              setSelectedCurrentAddress(e.target.value);
                              setFormData({
                                ...formData,
                                propertyOwnershipCorrespondingAddress: "",
                                residenceVintageCorrespondingAddress: ""
                              });
                              currentAddressResponse.map((item) => {
                                item.selectedByUser = false;
                              });

                              currentAddresses.map((item) => {
                                item.selectedByUser = false;
                              });
                              address.selectedByUser = true;
                            }}
                            disabled={sameAsPresentAddress}
                            label={`${address.addressLine1}, ${address.addressLine2}, ${address.city} ${address.pincode} ${address.state}`}
                          />
                        </div>
                        {!sameAsPresentAddress &&
                          <div className="col-4  d-flex  justify-content-end">
                            {" "}
                            <span
                              className="editPopupLable"
                              onClick={() => {
                                openPopupForEditCurrent(address);
                              }}
                            >
                              Edit{" "}
                            </span>{" "}
                            <span
                              className="editPopupLable"
                              onClick={handleDeleteNewCurrentAddress}
                            >
                              Delete{" "}
                            </span>
                          </div>
                        }
                      </div>
                      {address.selectedByUser && !sameAsPresentAddress && (
                        <div className="row mt-10">
                          <div className="col-md-6">
                            <ArthaFormControl fullWidth>
                              <InputLabel id="currentResidenceOwner-label">
                                Residence ownership
                              </InputLabel>
                              <Select
                                labelId="currentResidenceOwner-label"
                                id="currentResidenceOwner"
                                name="currentResidenceOwner"
                                value={
                                  formData.propertyOwnershipCorrespondingAddress
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    propertyOwnershipCorrespondingAddress:
                                      e.target.value,
                                  })
                                }
                              >
                                {propertyOwnershipCorrespondingAddressList.map(
                                  (item, index) => {
                                    return (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </ArthaFormControl>
                          </div>
                          <div className="col-md-6">
                            <ArthaFormControl fullWidth>
                              <InputLabel id="currentResidenceDuration-label">
                                Residence Duration
                              </InputLabel>
                              <Select
                                labelId="currentResidenceDuration-label"
                                id="currentResidenceDuration"
                                name="currentResidenceDuration"
                                value={
                                  formData.residenceVintageCorrespondingAddress
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    residenceVintageCorrespondingAddress:
                                      e.target.value,
                                  })
                                }
                                onOpen={fetchAddressVintage}
                              >

                                {residenceVintageCorrespondingAddressList.map(
                                  (item, index) => {
                                    const numericValue = item.replace(/\D/g, '');
                                    return (
                                      <MenuItem key={index} value={numericValue}>
                                        {item}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </ArthaFormControl>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-4 col-md-2">
                <Button
                  variant="contained"
                  className="upload_btn add-new-btn btn_white shadow-none mt-3"
                  onClick={openPopupForCurrent}
                  disabled={currentNewAddressCount > 0 || sameAsPresentAddress}
                >
                  Add New
                </Button>
                <BusinessLoanAddNewPopUp
                  popupLable={popupLable}
                  isOpen={isOpen}
                  onsaveClose={closeAfterSave}
                  closeModal={closeCurrentAddressModal}
                  addPermanentAddress={addPermanentAddress}
                  document={document}
                />

                <EditAddressPopUp
                  popupLable={popupLable}
                  isOpen={isEditOpen}
                  closeModal={closeEditModal}
                  addPermanentAddress={addPermanentAddress}
                  data={editPermAddressData}
                  document={document}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton variant="contained" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>
              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={isContinueButtonDisabled}
                  style={{
                    backgroundColor: isContinueButtonDisabled ? "orange" : "",
                    color: isContinueButtonDisabled ? "white" : "",
                    opacity: isButtonClicked ? 0.39 : (isContinueButtonDisabled ? 0.39 : 1),
                    pointerEvents: isButtonClicked ? "none" : "auto"
                  }}

                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form >
        </>
      ) : (
        <AdharOTPVerification

        />
      )}

    </>
  );
}