import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ArthaCancelButton from "../../fields/ArthaCancelButton";
import ArthaApplyButton from "../../fields/ArthaApplyButton";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";

import {
  propertyLoanBackPageAPI,
  propertyLoanFetchBusinessIncomeDetails,
  propertyLoanfetchTypeOfBusiness,
  propertyLoanStoreBusinessIncomeDetails,
  propertyLoanFetchDataAPI,
  propertyLoanFetchExistingIncomeAssessment,
} from "../../actioncreators/actioncreators";
import BankStatement from "./BankStatement";
const IncomeAssessmentDetails = ({ changeApplicantStatus }) => {
  const [response, setResponse] = useState();
  const navigate = useNavigate();
  const [businessCategory, setBusinessName] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [businessCashflowConfigId, setBusinessCashflowConfigId] = useState("");
  const [categoryProductMap, setCategoryProductMap] = useState({});
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]);
  const [typeOfBusinessOptions, setTypeOfBusinessOptions] = useState([]);
  const [typeOfIndustryOptions, setTypeOfIndustryOptions] = useState([]);
  const [isBusinessTypeSelected, setIsBusinessTypeSelected] = useState(false);
  const [businessMap, setBusinessMap] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [localEmployees, setLocalEmployees] = useState([
    {
      
      id: 1,
      productCategory:response?.userIncomeAssessment?.products?.productCategory,
      product: "",
      unitOfMeasurement: "",
      measurementFrequency: "",
      numberDaysInMonth: "",
      productOrServiceCount: "",
      unitSellingPrice: "",
      businessCashFlowConfigId: "",
    },
  ]);

  const productCategoryOptions = Object.keys(categoryProductMap || {});
  const measurementFrequencyOptions = ["Daily", "Monthly"];
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const fetchPurposeValues = async () => {
    setTypeOfBusinessOptions([]);
    try {
      const response = await propertyLoanfetchTypeOfBusiness();

      const businessMap = new Map();
      response.data.businesses.forEach((business) => {
        businessMap.set(business.businessCategory, business.businessCashflowId);
      });
      setBusinessMap(businessMap);
      const businessNames = response.data.businesses.map(
        (business) => business.businessCategory
      );
      setTypeOfBusinessOptions(businessNames);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  useEffect(() => {
    fetchPurposeValues();
  }, []);

  const fetchIndustryType = async () => {
    setTypeOfIndustryOptions([]);
    try {
      const payload = {
        type: "BUSINESS_LOAN",
        key: "INDUSTRY_TYPE",
        source: "json_value",
      };

      const response = await propertyLoanFetchDataAPI(payload);

      const industrytype = response?.data?.[0]?.List || [];
      setTypeOfIndustryOptions(industrytype);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  useEffect(() => {
    fetchIndustryType();
  }, []);

  const handleBusinessNameChange = (e) => {
    const selectedBusinessName = e.target.value;

    // Check if any data is filled against the selected business name
    const isDataFilledForSelectedBusiness = localEmployees?.some(
      (employee) =>
        employee.productCategory && employee.productCategory.trim() !== ""
    );
    console.log(
      "isDataFilledForSelectedBusiness",
      isDataFilledForSelectedBusiness
    );
    // If data is filled, check if selectedBusinessName is different from the current businessCategory
    if (isDataFilledForSelectedBusiness) {
      if (selectedBusinessName && selectedBusinessName !== businessCategory) {
        Swal.fire({
          title: "Warning",
          text: "Your previously filled data will be lost. Do you want to continue?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, continue",
          confirmButtonColor: "#ff7300",
          cancelButtonText: "No, cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            setBusinessName(selectedBusinessName);
            setIsBusinessTypeSelected(!!selectedBusinessName);

            if (selectedBusinessName) {
              // Retrieve the businessCashflowId for the selected business name
              const businessCashflowId = businessMap.get(selectedBusinessName);
              fetchBusinessIncomeDetails(businessCashflowId);
              setBusinessCashflowConfigId(businessCashflowId);

              // Reset all fields
              setLocalEmployees([
                {
                  id: 1,
                  productCategory: "",
                  product: "",
                  unitOfMeasurement: "",
                  measureFrequency: "",
                  numberDaysInMonth: "",
                  productOrServiceCount: "",
                  unitSellingPrice: "",
                  businessCashFlowConfigId: "",
                },
              ]);
            }
          } else {
            // Reset the select value to the previous business category if cancelled
            e.target.value = businessCategory;
          }
        });
      } else {
        // No change in selected business name, proceed without popup
        setBusinessName(selectedBusinessName);
        setIsBusinessTypeSelected(!!selectedBusinessName);

        if (selectedBusinessName) {
          const businessCashflowId = businessMap.get(selectedBusinessName);
          fetchBusinessIncomeDetails(businessCashflowId);
          setBusinessCashflowConfigId(businessCashflowId);
        }
      }
    } else {
      // No data filled, proceed directly without checking selectedBusinessName
      setBusinessName(selectedBusinessName);
      setIsBusinessTypeSelected(!!selectedBusinessName);

      if (selectedBusinessName) {
        const businessCashflowId = businessMap.get(selectedBusinessName);
        fetchBusinessIncomeDetails(businessCashflowId);
        setBusinessCashflowConfigId(businessCashflowId);
      }
    }
  };

  const handleIndustryTypeChange = (e) => {
    const selectedIndustryType = e.target.value;
    setIndustryType(selectedIndustryType);
  };

  const fetchExistingBusinessIncomeDetails = async () => {
    try {
      const fetchResponse = await propertyLoanFetchExistingIncomeAssessment();
      console.log("fetchResponse", fetchResponse);
      if (fetchResponse?.userIncomeAssessment?.businessCategory) {
        setBusinessName(fetchResponse?.userIncomeAssessment?.businessCategory);
        setIsBusinessTypeSelected(true);
      } else {
        setIsBusinessTypeSelected(false);
      }
      if (fetchResponse?.userIncomeAssessment?.industryType) {
        setIndustryType(fetchResponse?.userIncomeAssessment.industryType);
      }
      if (
        fetchResponse?.incomeAssessmentResponse
          ?.business_cashflow_config_details?.businessCashflowConfigDetails !==
        null
      ) {
        setResponse(fetchResponse);
        setBusinessCashflowConfigId(
          fetchResponse?.userIncomeAssessment?.businessCashflowConfigId
        );
      }
      if (fetchResponse?.userIncomeAssessment !== null) {
        setLocalEmployees(fetchResponse?.userIncomeAssessment?.products);
      }

      const formattedEmployees =
        fetchResponse?.incomeAssessmentResponse?.business_cashflow_config_details?.businessCashflowConfigDetails?.map(
          (employee, index) => ({
            ...employee,
            id: index + 1,
            product: employee.productsList ? employee.productsList[0] : "",
            unitOfMeasurement: "",
            measurementFrequency: "",
            numberDaysInMonth: "",
            productOrServiceCount: "",
            unitSellingPrice: "",
          })
        );

      setCategoryProductMap(
        formattedEmployees?.reduce((acc, item) => {
          acc[item.productCategory] = {
            products: item.productsList || [],
            unitsOfMeasurement: item.unitsOfMeasurement || [],
            profitPercentage: item.profitPercentage || 0,
          };
          return acc;
        }, {})
      );

      setUnitOfMeasurementOptions([]);

      console.log("Response:", fetchResponse);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchExistingBusinessIncomeDetails();
  }, []);

  const fetchBusinessIncomeDetails = async (businessCashflowConfigId) => {
    try {
      const requestBody = {
        businessCashflowConfigId: businessCashflowConfigId,
      };
      const fetchResponse = await propertyLoanFetchBusinessIncomeDetails(
        requestBody
      );
      setResponse(fetchResponse);

      const formattedEmployees =
        fetchResponse.incomeAssessmentResponse.businessCashflowConfigDetails.map(
          (employee, index) => ({
            ...employee,
            id: index + 1,
            product: employee.productsList ? employee.productsList[0] : "",
            unitOfMeasurement: "",
            measurementFrequency: "",
            numberDaysInMonth: "",
            productOrServiceCount: "",
            unitSellingPrice: "",
          })
        );

      setCategoryProductMap(
        formattedEmployees.reduce((acc, item) => {
          acc[item.productCategory] = {
            products: item.productsList || [],
            unitsOfMeasurement: item.unitsOfMeasurement || [],
            profitPercentage: item.profitPercentage || 0,
          };
          return acc;
        }, {})
      );

      setUnitOfMeasurementOptions([]);

      console.log("Response:", fetchResponse);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching data:", error);
    }
  };

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
        if (response?.data?.businessStatusCode === 2) {
          changeApplicantStatus(response?.data?.nextPage);
        } else {
          toast.error(response.data.message);
        }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  // Handle form submission
  const handleCalculate = async () => {
    setIsButtonClicked(true);
    setIsLoading(true);
    try {
      const payload = {
        products: localEmployees?.map((employee) => {
          const profitPercentage =
            categoryProductMap[employee.productCategory]?.profitPercentage;
          return {
            measurementFrequency: employee.measurementFrequency,
            numberDaysInMonth: parseInt(employee.numberDaysInMonth, 10),
            productCategory: employee.productCategory,
            productOrServiceCount: parseInt(employee.productOrServiceCount, 10),
            productSubCategory: employee.productSubCategory,
            unitOfMeasurement: employee.unitOfMeasurement,
            unitSellingPrice: parseFloat(employee.unitSellingPrice),
            profitPercentage: profitPercentage,
          };
        }),
        businessCategory: businessCategory,
        industryType: industryType,
        businessCashflowConfigId: businessCashflowConfigId,
      };

      const response = await propertyLoanStoreBusinessIncomeDetails(payload);
      if (
        response.data.businessStatusCode === 2 &&
        response.data.businessStatusSubCode === 2.2
      ) {
        changeApplicantStatus(response?.data?.nextPage);
      } else if (
        response.data.businessStatusCode === 2 &&
        response.data.businessStatusSubCode === 2.1
      ) {
        changeApplicantStatus(response?.data?.nextPage);
      }
      toast.success("Data submitted successfully");
    } catch (error) {
      console.error("Error submitting data:", error);
      handleApiError(error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleDelete = (index) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLocalEmployees(localEmployees?.filter((_, idx) => idx !== index));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your data has been deleted.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEmployees = localEmployees?.map((employee, idx) => {
      if (idx === index) {
        if (name === "productCategory") {
          const selectedCategory = categoryProductMap[value];
          return {
            ...employee,
            [name]: value,
            product: "",
            businessCashFlowConfigId: selectedCategory
              ? selectedCategory.businessCashFlowConfigId
              : "",
            measurementFrequency: "",
            numberDaysInMonth: "",
            productOrServiceCount: "",
            unitSellingPrice: "",
          };
        } else {
          return { ...employee, [name]: value };
        }
      }
      return employee;
    });
    setLocalEmployees(updatedEmployees);
  };

  const addRow = () => {
    const newEmployee = {
      id: localEmployees?.length + 1,
      productCategory: "",
      product: "",
      unitOfMeasurement: "",
      measurementFrequency: "",
      numberDaysInMonth: "",
      productOrServiceCount: "",
      unitSellingPrice: "",
      businessCashFlowConfigId: "",
    };
    setLocalEmployees([...localEmployees, newEmployee]);
  };

  const areAllFieldsFilled = () => {
    return localEmployees?.every((employee) => {
      return (
        employee.productCategory &&
        employee.productSubCategory &&
        employee.unitOfMeasurement &&
        employee.measurementFrequency &&
        employee.productOrServiceCount &&
        employee.unitSellingPrice
      );
    });
  };

  const buttonStyles = (isDisabled) => ({
    color: "white",
    // backgroundColor: isDisabled ? "lightgray" : "#ff7300",
    backgroundColor: "#ff7300",
    width: "120px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "25px",
    opacity: isButtonClicked || isDisabled ? 0.6 : 1,
    cursor: isDisabled ? "not-allowed" : "pointer",
    pointerEvents: isButtonClicked ? "none" : "auto",
  });

  return (
    <>
        <div className="row">
          <div
            className={"showTableContent"}
            style={{
              marginTop: "15px",
            }}
          >
            <div className="col-12 text-start mt-2">
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Business Income Details
              </span>
            </div>

            <div className="row mt-4">
              <div className="col-3"></div>
              <div className="col-6 d-flex">
                <label
                  className="col-4 fw-bold mt-2"
                  htmlFor="typeOfBusiness"
                  style={{ fontSize: "14px" }}
                >
                  Business Category :
                </label>
                <select
                  className="col-8 form-control form-control-sm"
                  id="typeOfBusiness"
                  value={businessCategory}
                  onChange={handleBusinessNameChange}
                >
                  <option value="">Select Business Category</option>
                  {typeOfBusinessOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>

              <div className="col-6 d-flex mt-3">
                <label
                  className="col-4 fw-bold mt-2"
                  htmlFor="typeOfBusiness"
                  style={{ fontSize: "14px" }}
                >
                  Industry Type :
                </label>
                <select
                  className="col-8 form-control form-control-sm"
                  id="industryType"
                  value={industryType}
                  onChange={handleIndustryTypeChange}
                >
                  <option value="">Select Industry Type</option>
                  {typeOfIndustryOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {isBusinessTypeSelected && (
              <div className="table-responsive mt-4">
                <table className=" table-striped">
                  <thead>
                    <tr
                      style={{
                        borderBottom: "1px solid",
                        backgroundColor: "lightgray",
                      }}
                    >
                      <th style={{ backgroundColor: "lightgrey" }}>Sr No.</th>
                      <th style={{ backgroundColor: "lightgrey" }}>
                        Product Category
                      </th>
                      <th style={{ backgroundColor: "lightgrey" }}>Product</th>
                      <th style={{ backgroundColor: "lightgrey" }}>
                        Unit of Measurement
                      </th>
                      <th style={{ backgroundColor: "lightgrey" }}>
                        Measurement Frequency
                      </th>
                      <th style={{ backgroundColor: "lightgrey" }}>
                        No of Days in a Month
                      </th>
                      <th style={{ backgroundColor: "lightgrey" }}>
                        Product/Service Count
                      </th>
                      <th style={{ backgroundColor: "lightgrey" }}>
                        Unit Selling Price
                      </th>
                      <th style={{ backgroundColor: "lightgrey" }}>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {localEmployees?.map((employee, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid lightGray",
                          margin: "3px",
                        }}
                      >
                        <td className="col-1">{index + 1}</td>
                        <td className="col-2">
                          <select
                            className="form-control"
                            name="productCategory"
                            value={employee.productCategory}
                            onChange={(e) => onChange(e, index)}
                          >
                            <option value="">Select Category</option>
                            {productCategoryOptions.map((category, idx) => (
                              <option key={idx} value={category}>
                                {category}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="col-2">
                          <select
                            className="form-control"
                            name="productSubCategory"
                            value={employee.productSubCategory}
                            onChange={(e) => onChange(e, index)}
                            disabled={!employee.productCategory}
                          >
                            <option value="">Select Product</option>
                            {employee.productCategory &&
                              categoryProductMap[
                                employee.productCategory
                              ]?.products.map((productSubCategory, idx) => (
                                <option key={idx} value={productSubCategory}>
                                  {productSubCategory}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td className="col-2">
                          <select
                            className="form-control"
                            name="unitOfMeasurement"
                            value={employee.unitOfMeasurement}
                            onChange={(e) => onChange(e, index)}
                            disabled={!employee.productCategory}
                          >
                            <option value="">Select Unit</option>
                            {employee.productCategory &&
                              categoryProductMap[
                                employee.productCategory
                              ]?.unitsOfMeasurement.map((unit, idx) => (
                                <option key={idx} value={unit}>
                                  {unit}
                                </option>
                              ))}
                          </select>
                        </td>

                        <td>
                          <select
                            className="form-control"
                            name="measurementFrequency"
                            value={employee.measurementFrequency}
                            onChange={(e) => onChange(e, index)}
                            disabled={!employee.productCategory}
                          >
                            <option value="">Select Frequency</option>
                            {measurementFrequencyOptions.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="numberDaysInMonth"
                            value={employee.numberDaysInMonth}
                            onChange={(e) => {
                              let value = e.target.value.replace(/[^0-9]/g, "");
                              if (
                                value !== "" &&
                                (parseInt(value) < 1 || parseInt(value) > 31)
                              ) {
                                value = Math.min(
                                  Math.max(parseInt(value), 1),
                                  31
                                );
                              }
                              onChange(
                                {
                                  target: {
                                    name: e.target.name,
                                    value: value.toString(),
                                  },
                                },
                                index
                              );
                            }}
                            disabled={
                              (!employee.measurementFrequency &&
                                !employee.productCategory) ||
                              employee.measurementFrequency === "Monthly"
                            }
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="productOrServiceCount"
                            value={employee.productOrServiceCount}
                            disabled={!employee.productCategory}
                            onChange={(e) => {
                              let value = e.target.value.replace(/[^0-9]/g, "");
                              if (value === "0") {
                                value = "";
                              }
                              onChange(
                                { target: { name: e.target.name, value } },
                                index
                              );
                            }}
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="unitSellingPrice"
                            value={employee.unitSellingPrice}
                            disabled={!employee.productCategory}
                            onChange={(e) => {
                              let value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (value === "0") {
                                value = "";
                              }
                              const parts = value.split(".");
                              if (parts.length > 2) {
                                value = parts[0] + "." + parts[1]; // Allow only one decimal point
                              } else if (
                                parts.length === 2 &&
                                parts[1].length > 2
                              ) {
                                value = parts[0] + "." + parts[1].slice(0, 2); // Allow only two digits after the decimal point
                              }
                              onChange(
                                { target: { name: e.target.name, value } },
                                index
                              );
                            }}
                            onBlur={(e) => {
                              let value = e.target.value;
                              if (value !== "") {
                                value = parseFloat(value).toFixed(2); // Format to two decimal places on blur
                                onChange(
                                  { target: { name: e.target.name, value } },
                                  index
                                );
                              }
                            }}
                          />
                        </td>

                        <td>
                          <span
                            className=" "
                            onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-start">
                  <button
                    onClick={addRow}
                    style={{
                      color: "white",
                      fontSize: "12px",
                      backgroundColor: "#ff7300", // Add a background color for better visibility
                      width: "100px", // Set your desired width
                      height: "40px", // Set your desired height
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none", // Remove default border
                      borderRadius: "5px", // Optional: Add border-radius for rounded corners
                      marginTop: "25px",
                    }}
                  >
                    Add Product
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-6 mt-4 justify-content-end ">
              <button
                className="float-end border"
                onClick={handleBack}
                style={{
                  color: "black",
                  backgroundColor: "white",
                  width: "120px", // Desired width
                  height: "45px", // Desired height
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none", // Remove default border
                  borderRadius: "25px", // Optional: Add border-radius for rounded corners
                }}
              >
                Back
              </button>
            </div>

            <div className="col-6 mt-4 ">
              <button
                onClick={handleCalculate}
                style={buttonStyles(
                  !(
                    isBusinessTypeSelected &&
                    industryType &&
                    areAllFieldsFilled()
                  )
                )}
                disabled={
                  !(
                    isBusinessTypeSelected &&
                    industryType &&
                    areAllFieldsFilled()
                  )
                }
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </div>
    </>
  );
};

export default IncomeAssessmentDetails;
